import React, {useEffect} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import PublicationMediaPreview from "./PublicationMediaPreview";


export default function PublicationMediaPreviewsContainer({items, areStories = false, disabled = false}) {
    const [draggableItems, setDraggableItems] = React.useState(items);
    useEffect(() => {
        setDraggableItems(items);
    }, [items]);


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newDraggableItems = reorder(
            draggableItems,
            result.source.index,
            result.destination.index
        );

        setDraggableItems(newDraggableItems);
    }


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className={'publication-media-preview-container gap-3 mt-3'+(snapshot.isDraggingOver ? ' draggable-over' : '')+(areStories ? ' stories-container' : '')+(disabled ? ' disabled' : '')}
                        {...provided.droppableProps}
                    >
                        {draggableItems.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <PublicationMediaPreview
                                        ref={provided.innerRef}
                                        provided={provided}
                                        isDragging={snapshot.isDragging}
                                        style={provided.draggableProps.style}
                                        media={item}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}