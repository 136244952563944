import React, {useEffect} from "react";
import Grades from "../../../helpers/Grades";

export default function ProgressCard({label = '', value = '', progress = 0, customGrading, progressHeight = '4px', bgFill = false}) {
    const defaultGrading = Grades.defaultGrading;
    const [grading, setGrading] = React.useState(defaultGrading);
    const [className, setClassName] = React.useState('light');


    const setClassNameByGrade = () => {
        const className = Grades.getClassNameByGrade(progress);

        setClassName(className);
    }


    useEffect(() => {
        setClassNameByGrade();
    }, [grading, progress]);


    useEffect(() => {
        if (customGrading) {
            setGrading(customGrading);
        }
    }, [customGrading]);


    return (
        <div className={'card'+(bgFill ? ` bg-light-${className} text-${className}` : '')}>
            <div className="card-body">
                <h3 className={'fs-6'+(bgFill ? ` text-${className}` : '')}>{value}</h3>
                <h6 className={'card-subtitle mb-2 '+(bgFill ? `text-${className}` : 'text-muted')}>{label}</h6>

                <div className={'progress '+(bgFill ? 'bg-white' : 'bg-light')} style={{height: progressHeight}}>
                    <div className={'progress-bar bg-'+className} role="progressbar" style={{width: progress+'%', height: progressHeight}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    );
}