import React from 'react';

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import {useAuth} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";


const Nav = (props) => {
    const authContext = useAuth();
    const userData = authContext.user;

    const apiContext = useApi();

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const onNavbarToggle = (e) => {
        e.preventDefault();

        const htmlClass = 'navbar-vertical-collapsed';
        const html = document.querySelector('html');

        if (html.classList.contains(htmlClass)) {
            html.classList.remove(htmlClass);
        } else {
            html.classList.add(htmlClass);
        }

        const navbarVertical = document.querySelector('#navbarVerticalCollapse');
        const navbarVerticalCollapse = new bootstrap.Collapse(navbarVertical, {
            toggle: false
        });
        navbarVerticalCollapse.toggle();
    }


    const onLogoutClick = (e) => {
        e.preventDefault();

        window.location.href = '/auth/logout';
    }


    const menuDefault = {
        'planning': {
            'label': 'Планування',
            'items': {
                'plans-recent': {
                    'icon': 'calendar-star',
                    'title': 'Контент-плани',
                    'url': '/'
                },
                'projects-list': {
                    'icon': 'building-store',
                    'title': 'Всі проєкти',
                    'url': '/projects',
                    'count': null
                },
            }
        },
        'analytics': {
            'label': 'Аналітика',
            'items': {
                'plans-recent': {
                    'icon': 'ti-chart-arcs-3',
                    'title': 'Статистика публікацій',
                    'url': '/'
                },
                'competitors': {
                    'icon': 'telescope',
                    'title': 'Конкуренти',
                    'url': '/projects',
                    'count': null
                },
            }
        },
        'paid': {
            'label': 'Просування',
            'items': {
                'paid-rules': {
                    'icon': 'coins',
                    'title': 'Авто-просування',
                    'url': '/reports'
                },
                'reports-activity': {
                    'icon': 'chart-line',
                    'title': 'Поточні витрати',
                    'url': '/reports'
                },
            }
        },
    }
    const [menu, setMenu] = React.useState(menuDefault);
    const isElementActive = (key, section) => {
        const simpleKeys = {
            'planning': ['plans-recent', 'projects-list'],
            'analytics': ['plans-recent', 'competitors'],
            'paid': ['paid-rules', 'reports-activity'],
        };

        if (simpleKeys[section].indexOf(key) !== -1) {
            if (window.location.pathname.indexOf(menu[section]['items'][key].url) !== -1) {
                return true;
            }
        }

        return false;
    }


    /*React.useEffect(() => {
        accountModel.getGeneralInfo().then((response) => {
            if (response !== false) {
                const newMenu = {...menu};
                newMenu['businesses']['items']['projects-list']['count'] = response.businessesCount;
                setMenu(newMenu);
            }
        });
    }, []);*/


    return (
        <aside className={'left-sidebar d-block d-xl-none'}>
            <div>
                <nav className="sidebar-nav scroll-sidebar container-fluid mw-100">
                    <ul id="sidebarnav">
                        {Object.keys(menu).map((section, sIndex) => {
                            return (
                                <React.Fragment key={sIndex}>
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                        <span className="hide-menu">{menu[section]['label']}</span>
                                    </li>
                                    {Object.keys(menu[section]['items']).map((key, index) => {
                                        const element = menu[section]['items'][key];
                                        return (
                                            <li className={`sidebar-item ${isElementActive(key, section) ? 'selected' : ''}`} key={sIndex + '-' + index}>
                                                <a className={`sidebar-link ${isElementActive(key, section) ? 'active' : ''}`} href={element.url}
                                                   aria-expanded="false">
                                                    <span>
                                                        <i className={`ti ti-${element.icon}`}></i>
                                                    </span>
                                                    <span className="hide-menu">{element.title}</span>
                                                    {typeof element.count === 'number' && (
                                                        <span className="hide-menu" style={{marginLeft: 'auto'}}><span
                                                            className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center round-20 p-0 fs-2">{element.count}</span></span>
                                                    )}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </nav>
            </div>
            <div className="fixed-profile p-2 sidebar-ad mt-3 mx-9 d-flex d-xl-none">
                <div className="hstack gap-3 justify-content-between">
                    <div className="john-img">
                        <img src={imgProfile} className="rounded-circle" width="40" height="40" alt=""/>
                    </div>
                    <div className="john-title">
                        <h6 className="mb-0 fs-4 fw-semibold">{userData.name}</h6>
                        <span className="fs-2 text-dark">{userData.type}</span>
                    </div>
                    <button className="border-0 bg-transparent text-primary ms-3" tabIndex="0" onClick={onLogoutClick}>
                        <i className="ti ti-logout fs-6"></i>
                    </button>
                </div>
            </div>
        </aside>
    );
}

export default Nav;
