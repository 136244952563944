import React from "react";
import SimpleBar from "simplebar-react";

import imgLogo from '../../../assets/img/logo.svg';
import imgIconDDChat from 'modernize-essentials/dist/images/svgs/icon-dd-chat.svg';
import imgIconDDInvoice from 'modernize-essentials/dist/images/svgs/icon-dd-invoice.svg';
import imgIconDDMobile from 'modernize-essentials/dist/images/svgs/icon-dd-mobile.svg';
import imgIconDDMessageBox from 'modernize-essentials/dist/images/svgs/icon-dd-message-box.svg';
import imgIconDDCart from 'modernize-essentials/dist/images/svgs/icon-dd-cart.svg';
import imgIconDDDate from 'modernize-essentials/dist/images/svgs/icon-dd-date.svg';
import imgIconDDLifebuoy from 'modernize-essentials/dist/images/svgs/icon-dd-lifebuoy.svg';
import imgIconDDApplication from 'modernize-essentials/dist/images/svgs/icon-dd-application.svg';

function OffcanvasMobileNav(props) {
    const onNavbarToggle = (e) => {
        e.preventDefault();

        if (e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('show');
        }
    }

    return (
        <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="mobilenavbar" aria-labelledby="offcanvasWithBothOptionsLabel">
            <nav className="sidebar-nav scroll-sidebar">
                <div className="offcanvas-header justify-content-between">
                    <img src={imgLogo} alt="" width={150} className="img-fluid" />
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <SimpleBar autoHide={true} className={'offcanvas-body profile-dropdown mobile-navbar'}>
                    <ul id="sidebarnav">
                        <li className="sidebar-item">
                            <a className="sidebar-link has-arrow" href="#" aria-expanded="false" onClick={onNavbarToggle}>
                                <span>
                                    <i className="ti ti-apps"></i>
                                </span>
                                <span className="hide-menu">Apps</span>
                            </a>
                            <ul aria-expanded="false" className="collapse first-level my-3">
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDChat} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Chat Application</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">New messages arrived</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDInvoice} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Invoice App</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">Get latest invoice</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDMobile} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Contact Application</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">2 Unsaved Contacts</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDMessageBox} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Email App</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">Get new emails</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDCart} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">User Profile</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">learn more information</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDDate} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Calendar App</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">Get dates</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDLifebuoy} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Contact List Table</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">Add new contact</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="sidebar-item py-2">
                                    <a href="#" className="d-flex align-items-center">
                                        <div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
                                            <img src={imgIconDDApplication} alt="" className="img-fluid" width="24" height="24" />
                                        </div>
                                        <div className="d-inline-block">
                                            <h6 className="mb-1 bg-hover-primary">Notes Application</h6>
                                            <span className="fs-2 d-block fw-normal text-muted">To-do and Daily tasks</span>
                                        </div>
                                    </a>
                                </li>
                                <ul className="px-8 mt-7 mb-4">
                                    <li className="sidebar-item mb-3">
                                        <h5 className="fs-5 fw-semibold">Quick Links</h5>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">Pricing Page</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">Authentication Design</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">Register Now</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">404 Error Page</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">Notes App</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">User Application</a>
                                    </li>
                                    <li className="sidebar-item py-2">
                                        <a className="fw-semibold text-dark" href="#">Account Settings</a>
                                    </li>
                                </ul>
                            </ul>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="app-chat.html" aria-expanded="false">
                                <span>
                                    <i className="ti ti-message-dots"></i>
                                </span>
                                <span className="hide-menu">Chat</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="app-calendar.html" aria-expanded="false">
                                <span>
                                    <i className="ti ti-calendar"></i>
                                </span>
                                <span className="hide-menu">Calendar</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="app-email.html" aria-expanded="false">
                                <span>
                                    <i className="ti ti-mail"></i>
                                </span>
                                <span className="hide-menu">Email</span>
                            </a>
                        </li>
                    </ul>
                </SimpleBar>
            </nav>
        </div>
    )
}

export default OffcanvasMobileNav;