import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsForm from "./ProjectsForm";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";

export default function ProjectsCreate(props) {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const metaContext = useMeta();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [redirect, setRedirect] = React.useState('');


    useEffect(() => {
        breadcrumbs.update({
            title: 'Додати новий бренд',
            breadcrumbs: [
                {label: 'Бренди', url: '/brands'},
                {label: 'Додати новий'}
            ]
        });

        metaContext.update({
            title: 'Додати новий проєкт',
        });

        setLoading(false);
    }, []);


    const onSubmit = (data) => {
        setLoading(true);

        projectsModel.create(data, onCreateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Бренд успішно створено', 'success');

                setRedirect('/projects/'+response.id);
            }
        }).catch((response) => {
            onCreateError(response);
        });
    }


    const onCreateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notificationsContext.notify('Не вдалося оновити бренд', 'error');
    }


    return (<>
        {redirect && <Navigate to={redirect}/>}
        <div className="d-flex m-block-gap">
            <Link to={'/brands'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до всіх брендів'}
            </Link>
        </div>
        <div className={'row'}>
            <div className={'col-md-8'}>
                <SimpleCardContainer title={'Додати новий бренд'} loading={loading}>
                    <ProjectsForm
                        formData={formData}
                        className={'py-2'}
                        errors={errors}
                        isNewRecord={true}
                        onSubmit={onSubmit}
                    />
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
