import React, { useEffect } from 'react';

import { Link } from "react-router-dom";
import CardLoading from "../../ui/CardLoading";

import { useApi } from "../../../providers/ApiProvider";

import defaultCover from '../../../assets/img/project-cover-default.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';
import ProjectEfficiencyChart from "./ProjectEfficiencyChart";
// import ProjectEfficiencyChartLine from "./ProjectEfficiencyChartLine";
import PublicationPlanButtonModal from "../../publications/publicationPlans/PublicationPlanButtonModal";

import PublicationsModel from "../../../models/Publications/PublicationsModel";

export default function ProjectEfficiencyCard({ loading, cardClassName = '', link, title, cover, logo, children }) {
	const apiContext = useApi();
	const publicationsModel = new PublicationsModel(apiContext.api);

	const tabs = [
		{ name: 'Instagram', label: 'Instagram' },
		{ name: 'Facebook', label: 'Facebook' },
		{ name: 'Stories', label: 'Stories' }
	];
	const stats = [
		{ id: 1, color: "bg-primary", title: "Охоплення", value: "23%" },
		{ id: 2, color: "bg-success", title: "Видимість", value: "18%" },
		{ id: 3, color: "bg-indigo", title: "Залученість", value: "12%" }
	];

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const formatDate = (date) => {
		return date.toISOString().slice(0, 10);  // формат YYYY-MM-DD
	};

	const options = [
		{
			value: "1",
			label: "за 7 днів",
			since: formatDate(new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate() - 5)),
		},
		{
			value: "2",
			label: "за 14 днів",
			since: formatDate(new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate() - 12)),
		},
		{
			value: "3",
			label: "за 30 днів",
			since: formatDate(new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate() - 28)),
		},
		{
			value: "4",
			label: "у цьому місяці",
			since: formatDate(new Date(yesterday.getFullYear(), yesterday.getMonth(), 2)),
		}
	];

	// console.log(222222222222);
	// console.log(options);

	const [dataItems, setDataItems] = React.useState({});
	const activeTab = React.useRef(tabs[0].label);
	const activeTabStat = React.useRef(stats[0].title);
	const selectedOption = React.useRef(options[0].value);

	const syncData = () => {
		const params = { platform: activeTab.current, stat: activeTabStat.current, since: options[+selectedOption.current - 1].since, for: formatDate(yesterday) };

		publicationsModel.coreEfficiency(params).then((response) => {
			if (response !== false) {
				const responseData = Object.keys(response).reduce((acc, date) => {
					response[date].forEach(item => {
						acc.planned.push({
							x: date,
							y: item.planned.reach
						});
						acc.actual.push({
							x: date,
							y: item.actual.reach
						});
					});
					return acc;
				}, { planned: [], actual: [] });
				setDataItems(responseData);
			}
		});
	}


	useEffect(() => {
		syncData();
	}, []);

	const handleTabClick = (tabName) => {
		activeTab.current = tabName;
		syncData();
	};

	const handleTabStatClick = (tabName) => {
		activeTabStat.current = tabName;
		syncData();
	};

	const handleSelectChange = (event) => {
		selectedOption.current = event.target.value;
		syncData();
	};

	return Object.keys(dataItems).length && (
		<div className={'card w-100 rounded-2 ' + cardClassName + (loading ? ' loading' : '')}>
			<div className="card-body p-3">
				<div className="d-sm-flex d-block align-items-start justify-content-between mb-2">
					<div className="d-flex align-items-start justify-content-start gap-5">
						<div className="mb-3 mb-sm-0">
							<h5 className="card-title fw-semibold">Ефективність публікацій</h5>
							<p className="card-subtitle mb-0">у порівнянні до завдання</p>
						</div>
						<div>
							<select
								className="form-select"
								value={selectedOption.current}
								onChange={handleSelectChange}
							>
								{options.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="d-flex justify-content-end">
						<PublicationPlanButtonModal />
						<button className="btn btn-primary">Переглянути поточний план</button>
					</div>
				</div>

				<ul className="nav nav-pills border-bottom user-profile-tab mb-4" role="tablist">
					{tabs.map(tab => (
						<li className="nav-item" role="presentation" key={tab.name}>
							<button
								className={`nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6 ${activeTab.current === tab.name ? 'active' : ''}`}
								onClick={() => handleTabClick(tab.name)}
								role="tab"
								aria-selected={activeTab.current === tab.name}
							>
								{tab.label}
							</button>
						</li>
					))}
				</ul>

				<div className="row">
					<div className="col-lg-9 col-md-8">
						<ProjectEfficiencyChart
							data={dataItems}
							barType={'bar'}
						/>
					</div>
					<div className="col-lg-9 col-md-8">
						<ProjectEfficiencyChart
							data={dataItems}
							barType={'line'}
						/>
					</div>
					<div className="col-lg-3 col-md-4 ps-lg-4 pt-lg-4">
						<div className="d-flex align-items-center mb-4 pb-1">
							<div className="p-8 bg-light-primary rounded-1 me-3 d-flex align-items-center justify-content-center">
								<i className="ti ti-grid-dots text-primary fs-6"></i>
							</div>
							<div>
								<h4 className="mb-0 fs-5 fw-semibold">7<span className="opacity-50">&nbsp;&nbsp;/&nbsp;&nbsp;7</span></h4>
								<p className="fs-3 mb-0">Опубліковано</p>
							</div>
						</div>

						<div>
							<div className="d-flex flex-column align-items-baseline mb-4">
								{stats.map(stat => (
									<button
										className={`nav-link position-relative rounded-0 d-flex align-items-baseline bg-transparent fs-3 py-6 ${activeTabStat.current === stat.title ? 'fw-bold' : ''}`}
										onClick={() => handleTabStatClick(stat.title)}
										role="tab"
										aria-selected={activeTab.current === stat.title}
									>
										<span className={`round-8 ${stat.color} rounded-circle me-6`}></span>
										<div>
											<p className="fs-3 mb-1">{stat.title}</p>
											<h6 className="fs-5 fw-semibold mb-0 text-start">{stat.value}</h6>
										</div>
									</button>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<CardLoading loading={loading} />
		</div>
	);
}
