import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import ContentPlansModel from "../../../models/Publications/PublicationPlansModel";
import {useParams} from "react-router-dom";
import Grades from "../../../helpers/Grades";


export default function PublicationPlanViewChecklist(props) {
    const {projectId, contentPlanId} = useParams();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);
    const contentPlansModel = new ContentPlansModel(apiContext.api);

    const [progress, setProgress] = React.useState(0);
    const [progressClassName, setProgressClassName] = React.useState('');


    useEffect(() => {
        const className = Grades.getClassNameByGrade(progress);
        setProgressClassName(className);
    }, [progress]);


    return (
        <div className="m-block-gap w-100 mb-5">
            <h5 className="d-flex justify-content-between align-items-center fw-semibold mb-3">
                Відповідність чек-лісту
                <span className={'badge fs-2 font-medium bg-' + progressClassName + ' text-white'}>{progress}%</span>
            </h5>

            <ul className="list-group">
                <li className="list-group-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="list1"/>
                        <label className="form-check-label" htmlFor="list1">
                            Todo list 1
                        </label>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="list2"/>
                        <label className="form-check-label" htmlFor="list2">
                            Todo list 2
                        </label>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="list3"/>
                        <label className="form-check-label" htmlFor="list3">
                            Todo list 3
                        </label>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="list4"/>
                        <label className="form-check-label" htmlFor="list4">
                            Todo list 4
                        </label>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="list5"/>
                        <label className="form-check-label" htmlFor="list5">
                            Todo list 5
                        </label>
                    </div>
                </li>
            </ul>
        </div>
    );
}
