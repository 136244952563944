import React from 'react';
import {Link} from "react-router-dom";
import CardLoading from "../../ui/CardLoading";

import defaultCover from '../../../assets/img/project-cover-default.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';
import BarChart from "../../ui/charts/BarChart";

export default function ProjectCompetitorsStats({loading, project}) {
    return (
        <div className="row">
            <div className="col-lg-4">
                <div className={'card card-sq-image overflow-hidden rounded-2' + (loading ? ' loading' : '')}>
                    <div className="card-body p-3 pt-2">
                        <div className="mb-3 mb-sm-0">
                            <h5 className="card-title fw-semibold">Кількість підписників</h5>
                            <p className="card-subtitle mb-0">у порівнянні з конкурентами</p>
                        </div>
                    </div>

                    <BarChart data={{current: [9, 7, 5, 1], average: [13, 9, 5, 3]}} height={'240px'} />

                    <CardLoading loading={loading}/>
                </div>
            </div>

            <div className="col-lg-4">
                <div className={'card card-sq-image overflow-hidden rounded-2' + (loading ? ' loading' : '')}>
                    <div className="card-body p-3 pt-2">
                        <div className="mb-3 mb-sm-0">
                            <h5 className="card-title fw-semibold">Вподобання в Instagram</h5>
                            <p className="card-subtitle mb-0">у порівнянні з конкурентами</p>
                        </div>
                    </div>

                    <BarChart data={{current: [9, 7, 5, 1], average: [13, 9, 5, 3]}} height={'240px'} />

                    <CardLoading loading={loading}/>
                </div>
            </div>

            <div className="col-lg-4">
                <div className={'card card-sq-image overflow-hidden rounded-2' + (loading ? ' loading' : '')}>
                    <div className="card-body p-3 pt-2">
                        <div className="mb-3 mb-sm-0">
                            <h5 className="card-title fw-semibold">Коментарі в Instagram</h5>
                            <p className="card-subtitle mb-0">у порівнянні з конкурентами</p>
                        </div>
                    </div>

                    <BarChart data={{current: [9, 7, 5, 1], average: [13, 9, 5, 3]}} height={'240px'} />

                    <CardLoading loading={loading}/>
                </div>
            </div>
        </div>
    );
}
