import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import {Link, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsForm from "./ProjectsForm";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import {useMeta} from "../../../providers/MetaProvider";

export default function ProjectsEdit(props) {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const metaContext = useMeta();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');


    const buildBreadcrumbs = () => {
        if (!formData) {
            return [];
        }

        let breadcrumbs = [
            {label: 'Проєкти', url: '/projects'}
        ];

        if (formData.title) {
            breadcrumbs.push({label: formData.title, url: '/projects/'+formData.id});
        }

        return breadcrumbs;
    }


    useEffect(() => {
        if (formData && formData.title) {
            breadcrumbs.update({
                title: 'Бренд «'+formData.title+'»',
                breadcrumbs: buildBreadcrumbs()
            });
        }

        metaContext.update({
            title: title,
            description: 'Редагування проєкту'
        });


        loadData().finally(() => {
            setLoading(false);
        });
    }, []);


    useEffect(() => {
        if (formData && formData.title) {
            breadcrumbs.update({
                title: formData.title,
                breadcrumbs: buildBreadcrumbs()
            });
        }

        metaContext.update({
            title: title,
            description: 'Редагування проєкту'
        });
    }, [title]);


    const loadData = () => {
        return projectsModel.view(params.projectId).then((response) => {
            if (response !== false) {
                setFormData(response);

                if (response.title) {
                    let text = response.title;
                    setTitle(text);
                }
            }
        });
    }


    const onSubmit = (data) => {
        setLoading(true);

        projectsModel.update(params.projectId, data, onUpdateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Дані проєкту успішно оновлено', 'success');
            }
        }).catch((response) => {
            onUpdateError(response);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notificationsContext.notify('Не вдалося оновити бренд', 'error');
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/projects'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                {'Назад до всіх брендів'}
            </Link>
        </div>
        <div className={'row'}>
            <div className={'col-md-8'}>
                <SimpleCardContainer title={title} loading={loading}>
                    <ProjectsForm
                        formData={formData}
                        className={'py-2'}
                        errors={errors}
                        isNewRecord={false}
                        onSubmit={onSubmit}
                    />
                </SimpleCardContainer>
            </div>
            <div className={'col-md-4'}>
                <SimpleCardContainer title={'Теми бренду'} loading={loading}>
                    <div className="d-flex justify-content-end mb-3">
                        <Link to={'/projects/'+params.projectId+'/themes/create'} className={'btn btn-light-success text-success'}>{'Додати нову тему'}</Link>
                    </div>

                    {loading ? (<div className={'text-center'}>Завантаження...</div>) : (<>
                        {formData.themes && formData.themes.length > 0 ? formData.themes.map((item, index) => {
                            return (<div key={index} className={'mb-3'}>
                                <div className={'mb-1'}>
                                    <Link to={'/projects/'+params.projectId+'/themes/'+item.id} className={'text-primary'}>{item.title}</Link><span className={'ms-3 fs-2 fw-semibold'}>Тегів: {item.tags.length}</span><br />
                                    {item.tags ? (<pre className={'m-0 mt-1 text-wrap'}>{'#'+item.tags.join(' #')}</pre>) : (<i className={'text-muted'}>Не вказано</i>)}
                                </div>
                            </div>);
                        }) : (<div className={'text-muted'}>Теми відсутні</div>)}
                    </>)}
                    <div className="d-flex justify-content-end mb-3">
                        <Link to={'/projects/'+params.projectId+'/themes'} className={'btn btn-light-info text-info'}>{'Переглянути всі теми'}</Link>
                    </div>
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
