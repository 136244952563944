import React from 'react';
import MultiRadialChart from "../../ui/charts/MultiRadialChart";

export default function ProjectCardStats({planned, published, efficiency, correspondence}) {
    const labels = ['Заплановано', 'Опубліковано', 'Ефективність', 'Відповідність'];


    const getClassName = (value) => {
        if (value < 50) {
            return 'danger';
        } else if (value < 85) {
            return 'warning';
        } else if (value < 105) {
            return 'success';
        } else {
            return 'primary';
        }
    }


    return (<>
        <div className={'d-flex gap-2 pt-2'}>
            <div className="d-flex flex-column gap-2 flex-grow-1 w-100">
                {[planned, published, efficiency, correspondence].map((item, index) => (
                    <div className="" key={index}>
                        <div className="d-flex justify-content-between fs-2 mb-1">
                            <div className="">{labels[index]}</div>
                            <div className="text-end">{Math.round(item)}%</div>
                        </div>
                        <div className="">
                            <div className="progress bg-light" style={{height: '3px'}}>
                                <div className={'progress-bar bg-'+getClassName(item)} role="progressbar" style={{width: item+'%', height: '3px'}} aria-valuenow={item} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <MultiRadialChart
                data={{
                    series: [planned, published, efficiency, correspondence],
                    labels: labels
                }}
                className=""
                shouldBeGraded={true}
                height={'160'}
            />
        </div>
    </>);
}
