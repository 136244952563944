import React, {useEffect} from "react";

import logoInstagram from '../../../assets/img/instagram.svg';
import PublicationCardProgress from "../common/PublicationCardProgress";
import AreaChart from "../../ui/charts/AreaChart";
import InsightsProgress from "../insights/InsightsProgress";
import {Link, useParams} from "react-router-dom";


export default function PublicationViewInsights({publication}) {
    const {projectId, contentPlanId, publicationId} = useParams();

    useEffect(() => {
        document.title = 'Статистика публікації від 7 лютого | Likengale';

        // Function to add the Instagram embed script
        const addInstagramScript = () => {
            if (!window.instgrm) {
                const script = document.createElement('script');
                script.src = "//www.instagram.com/embed.js";
                script.async = true;
                document.body.appendChild(script);
                script.onload = () => {
                    window.instgrm.Embeds.process();
                };
            } else {
                window.instgrm.Embeds.process();
            }
        };

        addInstagramScript();
    }, []);

    return (<>
        <div className="row">
            <div className="col-lg-3">
                <div className="d-flex w-100 justify-content-between mb-3">
                    <Link to={'/'+['p', projectId, contentPlanId].join('/')} className="btn btn-sm btn-light-primary text-primary d-inline-flex justify-content-center align-items-center gap-2">
                        <i className="ti ti-arrow-narrow-left fs-4"></i> Назад до плану
                    </Link>

                    <a href={'/'+['p', projectId, contentPlanId, publicationId].join('/')} className="bg-light rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                       title="Редагувати">
                        <i className="ti ti-pencil fs-3 text-dark"></i>
                    </a>
                </div>

                <div className="d-flex flex-column align-items-center m-block-gap">
                    <div className="position-relative">
                        <img src={logoInstagram} className="shadow-warning rounded-circle" alt="" width="72" height="72"/>
                        <div className="d-flex justify-content-center align-items-center rounded-circle bg-warning p-1 position-absolute"
                             style={{bottom: '-1px', right: '-1px'}}><i className="fs-2 text-white ti ti-star"></i></div>

                    </div>

                    <h5 className="fw-semibold fs-5 mt-2 mb-1">
                        Сервіс / Citroen Assistance <i className="fs-2 ms-2 text-danger ti ti-music position-absolute"></i>
                    </h5>
                    <p className="fs-3 mb-0">четвер, 21 березня</p>
                </div>

                <div className="w-100 m-block-gap">
                    <PublicationCardProgress progress={48} label={'Необхідно внести зміни до публікації.'} />
                </div>

                <div className="card w-100">
                    <div className="card-body p-3">
                        <div className="mb-4">
                            <h5 className="card-title fw-semibold mb-2">Активність для публікації</h5>
                            <p className="card-subtitle text-muted fs-2">Востаннє оновлено о 15:57.</p>
                        </div>
                        <ul className="timeline-widget mb-0 position-relative mb-n5">
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment received from John Doe of $385.90</div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">10:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-info flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                     className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment was made of $64.95 to Michael</div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-warning flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                     className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-danger flex-shrink-0 my-8"></span>
                                    <span className="timeline-badge-border d-block flex-shrink-0"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New arrival recorded <a href="#"
                                                                                                                        className="text-primary d-block fw-normal ">#ML-3467</a>
                                </div>
                            </li>
                            <li className="timeline-item d-flex position-relative overflow-hidden">
                                <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                    <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                </div>
                                <div className="timeline-desc fs-3 text-dark mt-n1">Payment Done</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex flex-column gap-1 m-block-gap">
                    <div className="alert alert-warning show d-flex align-items-center gap-2" role="alert">
                        <i className="ti ti-message-question fs-6 me-2"></i>
                        Деякі коментарі все ще без відповіді. <a href="#" className="alert-link fw-semibold text-nowrap">Переглянути »</a>
                    </div>

                    <div className="alert alert-danger show d-flex align-items-center gap-2" role="alert">
                        <i className="ti ti-photo-edit fs-6 me-2"></i>
                        Є зауваження, які очікують бути внесеними. <a href="#" className="alert-link fw-semibold text-nowrap">Переглянути »</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <h4>Доступність</h4>
                        <InsightsProgress
                            heading={'Видимість'}
                            hint={'Охоплення / Кількість підписників'}
                            className={'mt-4 mb-5'}
                            plan={{
                                current: 97,
                                total: 120,
                                currentLabel: '97%',
                                totalLabel: '120%'
                            }}
                            average={{
                                current: 97,
                                total: 87,
                                currentLabel: '97%',
                                totalLabel: '87%'
                            }}
                            best={{
                                current: 97,
                                total: 150,
                                currentLabel: '97%',
                                totalLabel: '150%'
                            }}
                        />
                        <PublicationCardProgress progress={105} label={'Перегляди / Охоплення'} heading={'Частотність'} className={'mt-3 mb-5'}/>

                        <h4>Ефективність</h4>
                        <InsightsProgress
                            heading={'Залученість'}
                            hint={'(Вподобання + Коментарі + Збереження) / Охоплення'}
                            className={'mt-4 mb-5'}
                            plan={{
                                current: 12,
                                total: 15,
                                currentLabel: '12%',
                                totalLabel: '15%'
                            }}
                            average={{
                                current: 12,
                                total: 14,
                                currentLabel: '12%',
                                totalLabel: '14%'
                            }}
                            best={{
                                current: 12,
                                total: 27,
                                currentLabel: '12%',
                                totalLabel: '27%'
                            }}
                        />
                        <PublicationCardProgress progress={60} label={'Коментарі + Збереження'} heading={'Сильні дії'} className={'mt-3 mb-5'}/>
                        <PublicationCardProgress progress={75} label={'(Коментарі + Збереження) / Охоплення'} heading={'Залученість до сильних дій'}
                                                 className={'mt-3 mb-5'}/>
                    </div>
                    <div className="col-lg-6">
                        <div className="card w-100 m-block-gap">
                            <div className="card-body p-0">
                                <h5 className="card-title fw-semibold m-4 mb-1 p-2 pb-0">Видимість</h5>
                                <p className="card-subtitle mx-4 px-2 mb-0">протягом першої години</p>

                                <AreaChart
                                    data={{
                                        current: [{x: '2024-03-23 16:05:00', y: 0}, {x: '2024-03-23 16:10:00', y: 10}, {
                                            x: '2024-03-23 16:15:00',
                                            y: 15
                                        }, {x: '2024-03-23 16:20:00', y: 18}, {x: '2024-03-23 16:25:00', y: 23}, {
                                            x: '2024-03-23 16:30:00',
                                            y: 27
                                        }, {x: '2024-03-23 16:35:00', y: 30}, {x: '2024-03-23 16:40:00', y: 41}, {
                                            x: '2024-03-23 16:45:00',
                                            y: 48
                                        }, {x: '2024-03-23 16:50:00', y: 75}, {x: '2024-03-23 16:55:00', y: 85}, {
                                            x: '2024-03-23 17:00:00',
                                            y: 95
                                        }, {x: '2024-03-23 17:05:00', y: 114}],
                                        average: [{x: '2024-03-23 16:05:00', y: 0}, {x: '2024-03-23 16:10:00', y: 7}, {
                                            x: '2024-03-23 16:15:00',
                                            y: 12
                                        }, {x: '2024-03-23 16:20:00', y: 15}, {x: '2024-03-23 16:25:00', y: 29}, {
                                            x: '2024-03-23 16:30:00',
                                            y: 31
                                        }, {x: '2024-03-23 16:35:00', y: 38}, {x: '2024-03-23 16:40:00', y: 45}, {
                                            x: '2024-03-23 16:45:00',
                                            y: 46
                                        }, {x: '2024-03-23 16:50:00', y: 64}, {x: '2024-03-23 16:55:00', y: 74}, {
                                            x: '2024-03-23 17:00:00',
                                            y: 98
                                        }, {x: '2024-03-23 17:05:00', y: 120}],
                                    }}
                                    className="py-3"
                                />

                                <div className="d-flex align-items-center justify-content-between m-4 p-2 mt-0 pt-0">
                                    <div className="d-flex align-items-center w-50">
                                        <div className="bg-light-primary rounded me-8 p-8 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-grid-dots text-primary fs-6"></i>
                                        </div>
                                        <div>
                                            <p className="fs-3 mb-0 fw-normal">Поточна</p>
                                            <h6 className="fw-semibold text-dark fs-4 mb-0">12%</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center w-50">
                                        <div className="bg-light rounded me-8 p-8 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-grid-dots text-muted fs-6"></i>
                                        </div>
                                        <div>
                                            <p className="fs-3 mb-0 fw-normal">Середня</p>
                                            <h6 className="fw-semibold text-dark fs-4 mb-0">10%</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card w-100 m-block-gap">
                            <div className="card-body p-0">
                                <h5 className="card-title fw-semibold m-4 mb-1 p-2 pb-0">Залученість</h5>
                                <p className="card-subtitle mx-4 px-2 mb-0">протягом першої години</p>

                                <AreaChart
                                    data={{
                                        current: [{x: '2024-03-23 16:05:00', y: 0}, {x: '2024-03-23 16:10:00', y: 10}, {
                                            x: '2024-03-23 16:15:00',
                                            y: 15
                                        }, {x: '2024-03-23 16:20:00', y: 18}, {x: '2024-03-23 16:25:00', y: 23}, {
                                            x: '2024-03-23 16:30:00',
                                            y: 27
                                        }, {x: '2024-03-23 16:35:00', y: 30}, {x: '2024-03-23 16:40:00', y: 41}, {
                                            x: '2024-03-23 16:45:00',
                                            y: 48
                                        }, {x: '2024-03-23 16:50:00', y: 75}, {x: '2024-03-23 16:55:00', y: 85}, {
                                            x: '2024-03-23 17:00:00',
                                            y: 93
                                        }, {x: '2024-03-23 17:05:00', y: 114}],
                                        average: [{x: '2024-03-23 16:05:00', y: 0}, {x: '2024-03-23 16:10:00', y: 7}, {
                                            x: '2024-03-23 16:15:00',
                                            y: 12
                                        }, {x: '2024-03-23 16:20:00', y: 15}, {x: '2024-03-23 16:25:00', y: 29}, {
                                            x: '2024-03-23 16:30:00',
                                            y: 31
                                        }, {x: '2024-03-23 16:35:00', y: 38}, {x: '2024-03-23 16:40:00', y: 45}, {
                                            x: '2024-03-23 16:45:00',
                                            y: 46
                                        }, {x: '2024-03-23 16:50:00', y: 64}, {x: '2024-03-23 16:55:00', y: 74}, {
                                            x: '2024-03-23 17:00:00',
                                            y: 98
                                        }, {x: '2024-03-23 17:05:00', y: 120}],
                                    }}
                                    className="py-3"
                                />

                                <div className="d-flex align-items-center justify-content-between m-4 p-2 mt-0 pt-0">
                                    <div className="d-flex align-items-center w-50">
                                        <div className="bg-light-primary rounded me-8 p-8 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-grid-dots text-primary fs-6"></i>
                                        </div>
                                        <div>
                                            <p className="fs-3 mb-0 fw-normal">Поточна</p>
                                            <h6 className="fw-semibold text-dark fs-4 mb-0">6%</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center w-50">
                                        <div className="bg-light rounded me-8 p-8 d-flex align-items-center justify-content-center">
                                            <i className="ti ti-grid-dots text-muted fs-6"></i>
                                        </div>
                                        <div>
                                            <p className="fs-3 mb-0 fw-normal">Середня</p>
                                            <h6 className="fw-semibold text-dark fs-4 mb-0">8%</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <blockquote className="instagram-media" data-instgrm-captioned
                            data-instgrm-permalink="https://www.instagram.com/p/C4yGdzxoKp5/"
                            data-instgrm-version="14"
                            style={{
                                background: '#fff',
                                border: 0,
                                borderRadius: '3px',
                                boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                                margin: '1px',
                                maxWidth: '540px',
                                minWidth: '326px',
                                padding: 0,
                                width: 'calc(100% - 2px)'
                            }}>
                </blockquote>
            </div>
        </div>
    </>);
}