import React from 'react';

import defaultLogo from '../../../assets/img/project-logo-default.png';

export default function ProjectTitleNumbers({loading, project}) {


    return project && (
        <div className="d-flex align-items-center justify-content-end mx-3 gap-5">
            <div className="text-center">
                <i className="ti ti-brand-instagram fs-9"></i>
            </div>
            <div className="text-center">
                <h4 className="mb-0 fw-semibold lh-1">938</h4>
                <p className="mb-0 fs-3">Підписників</p>
            </div>
            <div className="text-center">
                <h4 className="mb-0 fw-semibold lh-1">30%</h4>
                <p className="mb-0 fs-3">Охоплення</p>
            </div>
            <div className="text-center pe-5 border-1 border-end">
                <h4 className="mb-0 fw-semibold lh-1">10%</h4>
                <p className="mb-0 fs-3">Залученість</p>
            </div>

            <div className="text-center">
                <i className="ti ti-brand-facebook fs-9"></i>
            </div>
            <div className="text-center">
                <h4 className="mb-0 fw-semibold lh-1">938</h4>
                <p className="mb-0 fs-3">Підписників</p>
            </div>
            <div className="text-center">
                <h4 className="mb-0 fw-semibold lh-1">30%</h4>
                <p className="mb-0 fs-3">Охоплення</p>
            </div>
            <div className="text-center">
                <h4 className="mb-0 fw-semibold lh-1">10%</h4>
                <p className="mb-0 fs-3">Залученість</p>
            </div>
        </div>
    );
}
