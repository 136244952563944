import BaseCrudModel from "../BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ProjectsModel extends BaseCrudModel {
    baseUrl = '/projects';

    constructor(api) {
        super(api);
    }


    async statsShortWeekly(dateSince = null, errorHandler) {
        let url = this.baseUrl + '/stats-short-weekly';
        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }
}