import React from 'react';


const PublicationMediaPreview = React.forwardRef(({media = {preview: '', video: false}, provided, style, isDragging}, ref) => {

    return (
    <div
        className="publication-media-preview"
        ref={ref}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{...style}}
    >
        <div className="main-media">
            <img src={media.preview} alt={''} />
        </div>
        {media.video && (
        <div className="bg-primary text-white p-2 video-icon"><i className="ti ti-player-play fs-2"></i></div>
        )}

        <div className="media-toolbar gap-2">
            <button className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center"><i className="ti ti-external-link fs-4"></i></button>
            {media.video ? (
            <button className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center"><i className="ti ti-carousel-horizontal fs-4"></i></button>
            ) : (
            <button className="btn btn-sm btn-light btn-icon d-flex justify-content-center align-items-center"><i className="ti ti-crop fs-4"></i></button>
            )}
            <button className="btn btn-sm btn-danger btn-icon d-flex justify-content-center align-items-center"><i className="ti ti-trash fs-4"></i></button>
        </div>
    </div>);
});

export default PublicationMediaPreview;