import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Link} from "react-router-dom";

import {useApi} from "../../providers/ApiProvider";

function Home(props) {
    const breadcrumbs = useBreadcrumbs();


    const [dataItems, setDataItems] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Likengale';
    }, []);


    return (
        <>
        </>
);
}

export default Home;
