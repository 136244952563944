import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";
import PublicationPlanViewHeader from "./PublicationPlanViewHeader";
import ContentPlansModel from "../../../models/Publications/PublicationPlansModel";
import {useParams} from "react-router-dom";

import logoInstagram from './../../../assets/img/instagram.svg';
import logoFacebook from './../../../assets/img/facebook.svg';
import logoStories from './../../../assets/img/stories.svg';
import PublicationCard from "../common/PublicationCard";
import PublicationPlanViewPlatformProgress from "./PublicationPlanViewPlatformProgress";
import PublicationPlanViewChecklist from "./PublicationPlanViewChecklist";
import PublicationCardAdd from "../common/PublicationCardAdd";



export default function PublicationPlanView(props) {
    const {projectId, contentPlanId} = useParams();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);
    const contentPlansModel = new ContentPlansModel(apiContext.api);

    const [projectLoading, setProjectLoading] = React.useState(true);
    const [contentPlanLoading, setContentPlanLoading] = React.useState(true);
    const [project, setProject] = React.useState({});
    const [contentPlan, setContentPlan] = React.useState({});


    useEffect(() => {
        document.title = 'План Land Rover Харків від 7 лютого | Likengale';

        getContentPlanInfo();
    }, []);


    const getProjectInfo = () => {
        return projectsModel.view(projectId).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        });
    }


    const getContentPlanInfo = () => {
        setContentPlanLoading(true);

        getProjectInfo().then(() => {
            contentPlansModel.view(contentPlanId).then((response) => {
                if (response !== false) {
                    setContentPlan(response);
                }
            }).finally(() => {
                setContentPlanLoading(false);
            });
        });
    }


    return (
        <div className="row">
            <div className="col-lg-5 col-xl-4 col-xxl-3">
                <PublicationPlanViewHeader project={project} contentPlan={contentPlan} />
            </div>
            <div className="col-lg-7 col-xl-8 col-xxl-9 pt-1">
                <div className="row">
                    <div className="col-xl-6 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoInstagram} className="img-fluid" width={36} height={36} alt={'Instagram'}/>
                            <h5 className={'m-0 fw-semibold'}>Instagram</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationPlanViewChecklist />
                    </div>
                    <div className="col-xl-6 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoFacebook} className="img-fluid" width={36} height={36} alt={'Facebook'}/>
                            <h5 className={'m-0 fw-semibold'}>Facebook</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationPlanViewChecklist />
                    </div>
                    <div className="col-xl-12 col-xxl-4">
                        <div className="d-flex flex-column gap-3 align-items-center justify-content-center m-block-gap">
                            <img src={logoStories} className="img-fluid" width={36} height={36} alt={'Stories'}/>
                            <h5 className={'m-0 fw-semibold'}>Stories</h5>

                            <PublicationPlanViewPlatformProgress />
                        </div>

                        <PublicationCardAdd />

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationCard/>

                        <PublicationPlanViewChecklist />
                    </div>
                </div>
            </div>
        </div>
    );
}
