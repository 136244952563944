import React from "react";

import logoInstagram from './../../../assets/img/instagram.svg';
import logoFacebook from './../../../assets/img/facebook.svg';
import logoStories from './../../../assets/img/stories.svg';
import defaultCover from '../../../assets/img/post-4-5.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';
import PublicationCardProgress from "./PublicationCardProgress";
import { Link, useParams } from "react-router-dom";
import PublicationPlanViewPlatformProgress from "../publicationPlans/PublicationPlanViewPlatformProgress";


export default function PublicationPlanCard({ }) {

	// на вхід він отримує один масив: набір об'єктів (кожен об'єкт = публікація) з полями type та progress, 
	// де type один з sales, educational чи entertainment, а progress — десятичне значення відсотків, тобто 0.7 — це 70 %
	// і таким чином виводитимеш прогрес

	// а яким кольором зафарбовувати — це в ось цьому класі є функція:

	// src/helpers/Grades.js

	const dataInstagram = [
		{ type: 'sales', value: 70 },  // 70%
		{ type: 'educational', value: 50 },  // 50%
		{ type: 'educational', value: 50 },  // 50%
		{ type: 'educational', value: 50 },  // 50%
		{ type: 'educational', value: 50 },  // 50%
		{ type: 'educational', value: 50 },  // 50%
		{ type: 'entertainment', value: 90 }  // 90%
	];
	const dataFacebook = [
		{ type: 'informational', value: 50 },
		{ type: 'sales', value: 100 },
		{ type: 'entertainment', value: 60 },
		{ type: 'sales', value: 35 },
		{ type: 'entertainment', value: 74 },
	];
	const dataStories = [
		{ type: 'informational', value: 5 },
		{ type: 'sales', value: 100 },
		{ type: 'entertainment', value: 60 },
		{ type: 'sales', value: 35 },
		{ type: 'sales', value: 35 },
		{ type: 'sales', value: 35 },
		{ type: 'sales', value: 35 },
		{ type: 'sales', value: 35 },
		{ type: 'sales', value: 35 },
		{ type: 'entertainment', value: 74 },
	];

	const platforms = [
		{ id: 'instagram', logo: logoInstagram, data: dataInstagram },
		{ id: 'instagram', logo: logoInstagram, data: dataInstagram },
		{ id: 'facebook', logo: logoFacebook, data: dataFacebook },
		{ id: 'stories', logo: logoStories, data: dataStories }
	];

	function calculateAverage(data) {
		if (data.length === 0) return 0;
		const total = data.reduce((sum, item) => sum + item.value, 0);
		return total / data.length;
	}
	const overallAverage = React.useRef(calculateAverage(platforms.flatMap(platform => platform.data)));

	return (
		<div className="card w-100">
			<div className="card-body p-3">
				<div className="d-flex mb-4">
					<div className="flex-grow-1">
						<h5 className="card-title fw-semibold">План публікацій</h5>
						<p className="card-subtitle mb-0">на наступний тиждень</p>
					</div>
					<div className="bg-light-warning flex-grow-0 p-2 text-center">
						<h5 className="card-title fs-6 fw-semibold mb-1 text-warning">{overallAverage.current.toFixed(0)}%</h5>
						<p className="card-subtitle fs-2 mb-0 text-warning">готовність</p>
					</div>
				</div>
				{platforms.map(platform => (
					<div key={platform.id} className="d-flex flex-row align-items-center gap-3 w-100 mb-4">
						<div className="pt-1">
							<img src={platform.logo} className="img-fluid" width={24} height={24} alt={platform.id} />
						</div>
						<div className="flex-grow-1">
							<PublicationPlanViewPlatformProgress data={platform.data} />
							{/* <span>{calculateAverage(platform.data).toFixed(0)}%</span> */}
						</div>
					</div>
				))}
				<div className="d-flex justify-content-end w-100 gap-3">
					<Link to={'/p'} className="btn btn-sm btn-light-primary text-primary">Переглянути план</Link>
					<Link to={'/p'} className="btn btn-sm btn-primary d-flex justify-content-center"><i className="ti ti-archive fs-5"></i></Link>
				</div>
			</div>
		</div>
	);
}
