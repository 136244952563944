import React, {useEffect} from 'react';


export default function FormSingleImageFile({file, showProgress}) {
    const STATUS_PENDING = 'pending';
    const STATUS_UPLOADING = 'uploading';
    const STATUS_COMPLETE = 'complete';
    const STATUS_ERROR = 'error';

    const [imgSrc, setImgSrc] = React.useState(null);
    const [uploadStatus, setUploadStatus] = React.useState(STATUS_PENDING);
    const [customErrorHint, setCustomErrorHint] = React.useState(null);


    useEffect(() => {
        const reader = new FileReader();

        reader.onload = function (e) {
            setImgSrc(e.target.result);
        };

        reader.readAsDataURL(file);
    }, [file]);


    const getProgressBg = () => {
        if (uploadStatus === STATUS_PENDING) {
            return 'bg-muted';
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return 'bg-primary';
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return 'bg-success';
        }

        if (uploadStatus === STATUS_ERROR) {
            return 'bg-danger';
        }
    }


    const getUploadHint = () => {
        if (uploadStatus === STATUS_PENDING) {
            return {
                className: 'text-muted',
                text: 'Ready for upload'
            };
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return {
                className: 'text-primary',
                text: 'Uploading'
            };
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return {
                className: 'text-success',
                text: 'Upload complete'
            };
        }

        if (uploadStatus === STATUS_ERROR) {
            return {
                className: 'text-danger',
                text: 'Upload error'
            };
        }
    }


    const getProgressWidth = () => {
        if (uploadStatus === STATUS_PENDING) {
            return '15px';
        }

        if (uploadStatus === STATUS_UPLOADING) {
            return '70%';
        }

        if (uploadStatus === STATUS_COMPLETE) {
            return '100%';
        }

        if (uploadStatus === STATUS_ERROR) {
            return '100%';
        }
    }


    return (
        <div className="d-flex flex-row gap-3 align-items-center justify-content-start fs-2 fw-semibold text-primary mb-2">
            <div className={'image-upload-thumbnail'}>
                {imgSrc && (
                    <img src={imgSrc} alt={''} />
                )}
            </div>
            <div className={'d-flex flex-column gap-2 flex-grow-1'}>
                <div>{file.name}</div>

                {showProgress && (<>
                <div className="progress flex-grow-1 mt-n1">
                    <div className={'progress-bar progress-bar-striped progress-bar-animated '+getProgressBg()} style={{width: getProgressWidth(), height: '8px'}} role="progressbar"></div>
                </div>

                <div className={getUploadHint()['className']}>{getUploadHint()['text']}{customErrorHint ? '. '+customErrorHint : ''}</div>
                </>)}
            </div>
        </div>
    );
}
