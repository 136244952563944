import React, {useEffect, useState} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";

function ForgotPassword(props) {

    const apiContext = useApi();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === '') {
            setError('Please, enter your email.');
            return;
        }

        const credentials = {
            email: email
        }

        apiContext.api.authForgotPassword(credentials).then((response) => {
            if (response === false) {
                setError('Something went wrong.');
            } else {
                setSuccess(true);
            }
        });
    }


    useEffect(() => {
        document.title = 'Reset your password | Likengale';
    }, []);


    return (
        <>
            <div className="text-center">
                <h4 className="mb-0"> Forgot your password?</h4>

                {success && (
                    <div className="alert alert-primary" role="alert">Please, check your email for further instructions.</div>
                ) || !success && (<>
                    <small>Enter your email and we'll send you a reset link.</small>

                    {error !== '' && <div className="alert alert-danger" role="alert">{error}</div>}

                    <form className="mb-3 mt-4">
                        <input className="form-control" type="email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <div className="mb-3"></div>
                        <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" onClick={handleSubmit}>Send reset link</button>
                    </form>
                </>)}

                <Link className="fs--1 text-600" to="/auth/login"><span className="d-inline-block me-1">&larr;</span> Go back to login page</Link>
            </div>
        </>
    );
}

export default ForgotPassword;
