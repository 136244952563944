import React from "react";
import {useParams} from "react-router-dom";
import FormCheckbox from "../../layout/ui/FormCheckbox";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";


export default function PublicationEditForm({publication}) {
    const {projectId, contentPlanId, publicationId} = useParams();

    const [editMode, setEditMode] = React.useState(false);

    const [errors, setErrors] = React.useState({});
    const [publicationType, setPublicationType] = React.useState('publication.type');
    const [publicationPlatform, setPublicationPlatform] = React.useState('publication.platform');
    const [publicationTheme, setPublicationTheme] = React.useState('publication.theme');
    const [publicationDate, setPublicationDate] = React.useState('publication.date');
    const [publicationTime, setPublicationTime] = React.useState('publication.time');
    const [publicationNotes, setPublicationNotes] = React.useState('publication.notes');
    const [publicationPriority, setPublicationPriority] = React.useState('publication.priority');


    const resetForm = () => {
        setPublicationType('publication.type');
        setPublicationPlatform('publication.platform');
        setPublicationTheme('publication.theme');
        setPublicationDate('publication.date');
        setPublicationTime('publication.time');
        setPublicationNotes('publication.notes');
        setPublicationPriority('publication.priority');

        setEditMode(false);
    }


    return editMode ? (<>
        <SimpleCardContainer
            className="m-block-gap"
            cardBodyClassName={'p-3'}
        >
            <h5 className="card-title fw-semibold mb-3 cursor-pointer">Редагувати інформацію</h5>

            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="pub-type" className="form-label">Тип матеріалу</label>
                        <select className="form-select" value={publicationType} onChange={(e) => setPublicationType(e.target.value)} id="pub-type">
                            <option value="entertainment">Розважальний</option>
                            <option value="informational">Інформаційний</option>
                            <option value="sales">Продажний</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="pub-platform" className="form-label">Платформа</label>
                        <select className="form-select" value={publicationPlatform} onChange={(e) => setPublicationPlatform(e.target.value)} id="pub-platform">
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                            <option value="stories">Stories</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="form-group mb-3">
                <label htmlFor="pub-theme" className="form-label">Тема</label>
                <select className="form-select" value={publicationTheme} onChange={(e) => setPublicationTheme(e.target.value)} id="pub-theme">
                    <option value="Berlingo">Berlingo</option>
                    <option value="C3">C3</option>
                    <option value="SpaceTourer">SpaceTourer</option>
                </select>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="pub-date" className="form-label">Дата публікації</label>
                        <input type="date" className="form-control" value={publicationDate} onChange={(e) => setPublicationDate(e.target.value)} id="pub-date"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="pub-time" className="form-label">Час</label>
                        <input type="time" className="form-control" value={publicationTime} onChange={(e) => setPublicationTime(e.target.value)} id="pub-time"/>
                    </div>
                </div>
            </div>

            <div className="form-group mb-3">
                <label htmlFor="pub-notes" className="form-label">Нотатки до публікації</label>
                <textarea rows={3} className="form-control" value={publicationNotes} onChange={(e) => setPublicationNotes(e.target.value)} id="pub-notes"/>
            </div>

            <FormCheckbox
                label={'Пріоритетна публікація'}
                name={'priority'}
                errors={errors}
                onChange={(value) => {
                    setPublicationPriority(value);
                }}
                value={publicationPriority}
                groupClassName={'mb-3'}
            />

            <div className="d-flex gap-3">
                <ButtonWithLoading
                    label={'Зберегти'}
                    className={'btn-primary'}
                    onClick={() => {}}
                    iconClassName={"d-none"}
                    loading={false}
                />
                <button className="btn btn-outline-danger" onClick={resetForm}>Скасувати</button>
            </div>
        </SimpleCardContainer>
    </>) : (
        <div className="m-block-gap">
            <h5 className="card-title fw-semibold mb-2 cursor-pointer" onClick={() => {
                setEditMode(!editMode)
            }}>
                Інформація про публікацію
                <span className="py-1 ms-3 d-inline-flex align-items-center text-decoration-none" title="Редагувати">
                    <i className="ti ti-pencil fs-3 text-dark"></i>
                </span>
            </h5>
            <p className="card-subtitle text-muted fs-2 mb-3">Натисніть для редагування.</p>

            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <h4 className="fs-2 fw-semibold text-muted mb-0">Тип матеріалу</h4>
                        <div className="fs-4 position-relative">Розважальний<i className="fs-2 ms-2 text-danger ti ti-music position-absolute"></i></div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <h4 className="fs-2 fw-semibold text-muted mb-0">Платформа</h4>
                        <div className="fs-4 position-relative">Instagram</div>
                    </div>
                </div>
            </div>

            <div className="form-group mb-3">
                <h4 className="fs-2 fw-semibold text-muted mb-0">Нотатки</h4>
                <div className="fs-4 position-relative">За фото <a href="https://planner.komliev.studio/a~publications/update?id=8824" target="_blank"><i
                    className="ti ti-external-link fs-4"></i></a></div>
            </div>

            <div className="row mb-n3">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <h4 className="fs-2 fw-semibold text-muted mb-0">Контент-план</h4>
                        <div className="fs-4 position-relative">від 18 березня</div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <h4 className="fs-2 fw-semibold text-muted mb-0">Статус</h4>
                        <div className="fs-4 position-relative">Нова публікація</div>
                    </div>
                </div>
            </div>
        </div>
    );
}