import React, {useEffect} from 'react';

import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import FormTextField from "../../layout/ui/FormTextField";
import FormTextArea from "../../layout/ui/FormTextArea";
import FormCheckbox from "../../layout/ui/FormCheckbox";
import FormSingleImage from "../../layout/ui/FormSingleImage";
import FormSelect from "../../layout/ui/FormSelect";

export default function ProjectsForm({formData, onSubmit, errors, loading, isNewRecord, className = ''}) {
    const [data, setData] = React.useState({});


    useEffect(() => {
        setData(formData);
    }, [formData]);


    const updateField = (field, value) => {
        let newData = {...data};
        newData[field] = value;

        setData(newData);
    }


    const onSubmitClick = (e) => {
        e.preventDefault();

        if (!data.hasOwnProperty('is_active')) {
            data.is_active = 0;
        }

        onSubmit(data);
    }


    return (
        <form encType="multipart/form-data" className={className}>
            <div className={'row g-4'}>
                <div className={'col-md-2'}>
                    <FormTextField
                        label={'ID'}
                        name={'id'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('id', value)
                        }}
                        value={data.id}
                        required={true}
                        disabled={!isNewRecord}
                    />
                </div>
                <div className={'col-md-4'}>
                    <FormTextField
                        label={'Назва'}
                        name={'title'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('title', value)
                        }}
                        value={data.title}
                        required={true}
                    />
                </div>
                <div className={'col-md-3'}>
                    <FormTextField
                        label={'Аліас сторінки Facebook'}
                        name={'facebook_username'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('facebook_username', value)
                        }}
                        value={data.facebook_username}
                        required={false}
                    />
                </div>
            </div>

            <div className={'row g-4'}>
                <div className={'col-md-6'}>
                    <FormSingleImage
                        label={'Логотип'}
                        name={'logo'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('logo', value)
                        }}
                        value={data.logo}
                        groupClassName={'mt-4'}
                    />
                </div>
                <div className={'col-md-6'}>
                    <FormSingleImage
                        label={'Обкладинка'}
                        name={'cover'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('cover', value)
                        }}
                        value={data.cover}
                        groupClassName={'mt-4'}
                    />
                </div>
            </div>

            <FormTextField
                label={'Теги'}
                name={'tags'}
                errors={errors}
                onChange={(value) => {
                    updateField('tags', value)
                }}
                value={data.tags}
                required={false}
                groupClassName={'mt-4'}
            />

            <FormTextArea
                label={'Базовий AI Запит'}
                name={'ai_system_prompt'}
                errors={errors}
                onChange={(value) => {
                    updateField('ai_system_prompt', value)
                }}
                value={data.ai_system_prompt}
                rows={15}
                className={'form-control'}
                groupClassName={'mt-4'}
            />

            <FormTextArea
                label={'Додаткові параметри'}
                name={'data'}
                errors={errors}
                onChange={(value) => {
                    updateField('data', value)
                }}
                value={data.data}
                hint={'JSON-формат.'}
                className={'form-control'}
                isCodeMirror={true}
                groupClassName={'mt-4'}
            />

            <FormCheckbox
                label={'Активний'}
                name={'is_active'}
                errors={errors}
                onChange={(value) => {
                    updateField('is_active', value)
                }}
                value={data.is_active}
                groupClassName={'mt-4'}
            />

            <div className={'form-group mt-4'}>
                <ButtonWithLoading
                    className={isNewRecord ? 'btn-success' : 'btn-primary'}
                    label={isNewRecord ? 'Додати новий бренд' : 'Зберегти зміни'}
                    loading={loading}
                    noOriginalIconClassName={true}
                    iconClassName={'ti fs-5 me-2 ' + ((isNewRecord) ? 'ti-plus' : 'ti-device-floppy')}
                    onClick={onSubmitClick}
                />
            </div>
        </form>
    );
}
