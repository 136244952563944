import React from "react";

import defaultCover from '../../../assets/img/post-4-5.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';
import PublicationCardProgress from "./PublicationCardProgress";
import {Link, useParams} from "react-router-dom";


export default function PublicationCard(props) {
    const {projectId, contentPlanId} = useParams();

    return (
        <Link to={'/'+['p',projectId,contentPlanId,'123'].join('/')} className="card w-100">
            <div className="publication-card-media-container bg-light">
                <div className="position-absolute bottom-0 end-0 p-2 px-3 d-flex flex-row justify-content-end align-items-center">
                    <span className="badge fs-2 font-medium bg-success text-white">На узгодженні</span>
                </div>
            </div>
            <div className="p-3 pt-4 mt-n4 text-center">
                <div className="d-flex flex-row justify-content-between align-items-end position-relative mt-n4">
                    <div className="pb-1 w-100 text-start">
                        <span className="badge fs-2 font-medium bg-light-gray text-dark">четвер, 23.11</span>
                    </div>
                    <div
                        className="align-items-center bg-light-danger border border-3 border-white d-flex justify-content-center rounded-circle mx-auto position-relative"
                        style={{width: '64px', minWidth: '64px', height: '64px'}}>
                        <img src={defaultCover} className="rounded-circle object-fit-cover overflow-hidden" width={58} height={58}/>

                        <div className="d-flex justify-content-center align-items-center position-absolute">
                            <i className="fs-8 text-white ti ti-player-play"></i>
                        </div>

                        <div className="d-flex justify-content-center align-items-center rounded-circle bg-warning p-1 position-absolute"
                             style={{bottom: '-3px', right: '-3px'}}>
                            <i className="fs-2 text-white ti ti-star"></i>
                        </div>
                    </div>
                    <div className="pb-1 w-100 gap-2 d-flex flex-row justify-content-end align-items-center">
                        <span className="badge fs-1 font-medium bg-info-subtle text-info bg-opacity-75">4:5</span>
                        <span className="badge fs-1 font-medium bg-info-subtle text-info bg-opacity-75">Карусель</span>
                    </div>
                </div>
                <div className="text-start">
                    <h6 className="mb-0 fw-semibold mt-3 mb-2">
                        Сервіс / Citroën Assistance
                        <i className="fs-2 ms-2 text-danger ti ti-music"></i>
                        <i className="fs-2 ms-2 text-primary ti ti-school"></i>
                        <i className="fs-2 ms-2 text-dark ti ti-tags"></i>
                    </h6>
                    <p className="m-0 text-muted">Шукаєте спокій і впевненість під час поїздок? 🚗✨ Citroën Assistance — це ваш надійний помічник на дор...</p>
                </div>

                <PublicationCardProgress progress={48} label={'Необхідно внести зміни до публікації.'} />

                <div className="d-flex align-items-center gap-2 pt-4 d-none">
                    <img src={defaultLogo} alt={'author'} className="rounded-circle" width={48} height={48}/>
                    <div className="align-items-start d-flex flex-column">
                        <h6 className="mb-0 fw-semibold">John Caldwins</h6>
                        <span className="fs-2">Оновлено у четвер, 21 листопада</span>
                    </div>
                    <div className="ms-auto text-end">
                    </div>
                </div>
            </div>
        </Link>
    );
}