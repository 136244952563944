import BaseCrudModel from "../BaseCrudModel";

/**
 * Publications model
 *
 * @param {ApiHelper} api
 */
export default class PublicationsModel extends BaseCrudModel {
	baseUrl = '/publications';

	constructor(api) {
		super(api);
	}


	async coreEfficiency(params = {}, errorHandler) {
		let url = this.baseUrl + '/core-efficiency';
		if (Object.keys(params).length) {
			url += '?' + new URLSearchParams(params).toString();
		}

		console.log(11111111111);
		console.log(params);

		const response = await this.api.getRequest(url);

		return this.processResponse(response, errorHandler);
	}
}