import React, {useEffect, useState} from 'react';

import {Link, useOutletContext} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import GoogleLogin from "./GoogleLogin";

const Login = (props) => {

    const apiContext = useApi();

    const parentContext = useOutletContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === '' || password === '') {
            setError('Please, fill in all fields.');
            return;
        }

        const credentials = {
            email: email,
            password: password,
            rememberMe: rememberMe
        }

        apiContext.api.authLogin(credentials).then((response) => {
            if (response === false || !response.data || !response.data.access_token) {
                setError('Wrong credentials.');
            } else {
                parentContext.onSuccessfulAuth(response.data);
            }
        });
    }


    useEffect(() => {
        document.title = 'Login | Likengale';
    }, []);


    return (
        <>
            <form>
                {error !== '' && <div className="alert alert-danger" role="alert">{error}</div>}

                <div className="mb-4">
                    <label htmlFor="split-login-email" className="form-label">Email address</label>
                    <input type="email" className="form-control" id="split-login-email" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="mb-4">
                    <label htmlFor="split-login-password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="split-login-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="form-check">
                        <input className="form-check-input primary" type="checkbox" id="split-checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                        <label className="form-check-label text-dark" htmlFor="split-checkbox">
                            Remember me
                        </label>
                    </div>
                    <Link className="text-primary fw-medium" to="/auth/forgot-password">Forgot Password?</Link>
                </div>


                <div className="d-flex align-items-center justify-content-between mb-4">
                    <Link className="btn-outline-p fw-medium py-8 text-primary" to="/auth/register">Create new Account</Link>
                    <button className="btn btn-primary w-40 py-8 rounded-2" type="submit" name="submit" onClick={handleSubmit}>Sign In</button>
                </div>
            </form>

            <div className="position-relative text-center my-4">
                <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">or</p>
                <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
            </div>

            <div className="row">
                <div className="col-12 mb-2 mb-sm-0">
                    <GoogleLogin />
                </div>
            </div>
        </>
    );
}

export default Login;
