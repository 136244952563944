import React from 'react';

import imgLogo from '../../assets/img/logo.svg';
import HeaderProfile from "./ui/HeaderProfile";
import HeaderNotifications from "./ui/HeaderNotifications";
import {Link} from "react-router-dom";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        window.addEventListener('scroll', function () {
            if (document.querySelector('.app-header')) {
                if (window.scrollY > 60) {
                    document.querySelector('.app-header').classList.add('fixed-header');
                } else {
                    document.querySelector('.app-header').classList.remove('fixed-header');
                }
            }
        });

        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }


    onWindowResize() {
        if (document.querySelector('#main-wrapper')) {
            if (window.innerWidth < 1300) {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'mini-sidebar');
                document.querySelector('#main-wrapper').classList.add('mini-sidebar');
            } else {
                document.querySelector('#main-wrapper').setAttribute('data-sidebartype', 'full');
                document.querySelector('#main-wrapper').classList.remove('mini-sidebar');
            }
        }
    }


    render() {
        return (<>
            <header className="app-header px-0">
                <nav className="navbar navbar-expand-xl navbar-light container-fluid px-0 mw-100">
                    <ul className="navbar-nav d-none d-xl-flex w-100 ps-3">
                        <li className="nav-item me-4">
                            <a className="nav-link fs-4 fw-semibold " href="/p"><i className="ti ti-calendar-star fs-4 me-2"></i>Планування</a>
                        </li>
                        <li className="nav-item me-4">
                            <a className="nav-link fs-4 fw-semibold" href="/a"><i className="ti ti-chart-arcs-3 fs-4 me-2"></i>Аналітика</a>
                        </li>
                        <li className="nav-item me-4">
                            <a className="nav-link fs-4 fw-semibold" href="/c"><i className="ti ti-coins fs-4 me-2"></i>Просування</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item d-block d-xl-none">
                            <a className="nav-link sidebartoggler px-4" href="#" onClick={this.props.onSidebarToggle}>
                                <i className="ti ti-menu-2"></i>
                            </a>
                        </li>
                        <li className="nav-item d-none d-xl-block">
                            <Link to={'/'} className={'text-nowrap nav-link'}>
                                <img src={imgLogo} width="150" alt=""/>
                            </Link>
                        </li>
                    </ul>
                    <div className="d-block d-xl-none">
                        <Link to={'/'} className="text-nowrap nav-link">
                            <img src={imgLogo} width="150" alt=""/>
                        </Link>
                    </div>

                    <ul className="navbar-nav flex-row align-items-center justify-content-end w-xl-100">
                        <li className="nav-item dropdown">
                            <HeaderNotifications/>
                        </li>
                        <li className="nav-item dropdown d-none d-xl-block pe-3">
                            <HeaderProfile/>
                        </li>
                    </ul>
                </nav>
            </header>
        </>);
    }
}

export default Header;
