import React, {useEffect} from "react";

export default function FormCheckbox({label, name, errors, onChange, value, groupClassName = '', className = '', required = false, disabled = false, readOnly = false, autoComplete = 'off', autoFocus = false, hint = ''}) {

    useEffect(() => {
        if (value === undefined || value === null) {
            onChange(0);
        }
    }, [value]);

    const onFieldChange = (e) => {
        onChange(e.target.checked ? 1 : 0);
    }

    return (
        <>
            <div className={'form-check form-check-inline ' + groupClassName}>
                <input className="form-check-input" type="checkbox" id={'cf-' + name} checked={!!value} onChange={onFieldChange} />
                <label className="form-check-label" htmlFor={'cf-' + name}>{label}</label>
            </div>
            <div className="d-flex justify-content-between gap-3 mt-1">
                {hint && (<div className={'flex-grow-1 text-start fs-2'}>{hint}</div>)}
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
            </div>
        </>
    )
}