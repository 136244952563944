import BaseCrudModel from "../BaseCrudModel";

/**
 * Publication Plans model
 *
 * @param {ApiHelper} api
 */
export default class PublicationPlansModel extends BaseCrudModel {
    baseUrl = '/projects/{%PROJECT_ID%}/plans';

    constructor(api, projectId) {
        super(api);
        this.baseUrl = this.baseUrl.replace('{%PROJECT_ID%}', projectId);
    }
}
