import React, {useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone'
import PublicationAddFile from "./PublicationAddFile";

import PublicationMediaPreviewsContainer from "./PublicationMediaPreviewsContainer";
import imgTmp1 from './../../../assets/img/project-cover-default.png';
import imgTmp2 from './../../../assets/img/user-profile-default.png';
import imgTmp3 from './../../../assets/img/post-9-16.png';
import imgTmp4 from './../../../assets/img/project-logo-default.png';
import imgTmp5 from './../../../assets/img/product-card-default.jpg';


export default function PublicationEditMedia({publication, captionEditMode = false}) {
    const onDrop = useCallback(acceptedFiles => {
        setFilesToUpload(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const [selectedCategory, setSelectedCategory] = React.useState('ADDITIONAL');
    const [uploadRunning, setUploadRunning] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [fileUploadStatuses, setFileUploadStatuses] = React.useState({});
    const [uploadComplete, setUploadComplete] = React.useState(false);


    const onUploadClick = () => {
        let statuses = {};
        for (let i = 0; i < filesToUpload.length; i++) {
            statuses[i] = 'pending';
        }
        setFileUploadStatuses(statuses);

        setUploadRunning(true);
    }


    const onPerformUpload = (index, status) => {
        setFileUploadStatuses(prevStatus => ({
            ...prevStatus,
            [index]: status
        }));
    }


    useEffect(() => {
        onUploadClick();
    }, [filesToUpload]);


    useEffect(() => {
        let statuses = Object.values(fileUploadStatuses);
        if (statuses.length === 0) {return;}

        if (statuses.indexOf('pending') === -1 && statuses.indexOf(false) === -1) {
            setUploadComplete(true);
        }

        if (statuses.indexOf('pending') === -1) {
            setUploadRunning(false);
        }
    }, [fileUploadStatuses]);


    return (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3">Медіа до публікації</h4>
        <div className="mb-5">
            {!captionEditMode && (<>
            <div {...getRootProps()} className={'drop-area'+((isDragActive)?' active':'')}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="m-0">{'Drop the files here...'}</p> :
                        <p className="m-0">{'Drag \'n\' drop some files here, or click to select files'}</p>
                }
            </div>

            <div className="mt-3">
                {filesToUpload && filesToUpload.map((file, index) => (
                    <PublicationAddFile
                        key={index}
                        file={file}
                        selectedCategory={selectedCategory}
                        performUpload={uploadRunning}
                        onPerformUpload={(status) => {
                            onPerformUpload(index, status);
                        }}
                    />
                ))}
            </div>
            </>)}

            <PublicationMediaPreviewsContainer
                disabled={captionEditMode}
                areStories={true}
                items={[
                    {id: '1', preview: imgTmp1, video: false},
                    {id: '2', preview: imgTmp2, video: false},
                    {id: '3', preview: imgTmp3, video: true},
                    {id: '4', preview: imgTmp4, video: false},
                    {id: '5', preview: imgTmp5, video: false},
                ]}
            />
        </div>
    </>
    );
}