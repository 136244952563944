import React from "react";
import {Link} from "react-router-dom";


export default function PublicationCardAdd(props) {
    return (
        <Link to={'#'} className="align-items-center bg-light border-dark-light d-flex justify-content-center m-block-gap text-body-color w-100" style={{height: '150px'}}>
            <i className="ti ti-plus fs-13"></i>
        </Link>
    );
}