import React from 'react';

import defaultLogo from '../../../assets/img/project-logo-default.png';

export default function PublicationPlanViewHeader({project, contentPlan}) {
    if (!project || !contentPlan) {
        return null;
    }


    //convert GMT to local time as DD MMM + YYYY if year differs from today's
    const getDateSinceFormatted = () => {
        if (!contentPlan.date_since) {
            return '';
        }

        const date = new Date(contentPlan.date_since);
        const today = new Date();
        let dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};

        if (date.getFullYear() === today.getFullYear()) {
            dateOptions = {day: 'numeric', month: 'long'};
        }

        return date.toLocaleDateString('uk-UA', dateOptions);
    }


    return (<>
        <div className="px-3 m-block-gap">
            <div className="d-flex align-items-center pb-2">
                <div className="me-2 pe-1">
                    <img src={project && project.logo ? project.logo : defaultLogo} className="shadow-warning rounded-circle" alt="" width="72" height="72"/>
                </div>
                <div className="w-100 d-flex align-items-start">
                    <div className="w-100">
                        <h5 className="fw-semibold fs-5 mb-1 position-relative">
                            {project.title}
                        </h5>
                        <p className="fs-3 mb-0">від {getDateSinceFormatted()}</p>
                    </div>
                    <div className="d-flex gap-2 ms-3">
                        <a href="#" className="bg-light rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                           title="Редагувати">
                            <i className="ti ti-pencil fs-3 text-dark"></i>
                        </a>
                        <a href="#" className="bg-light-primary rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                           target="_blank" title="Оформлений план">
                            <i className="ti ti-share fs-3 text-primary"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="fs-3 mt-2 mb-3">
                <span className="fw-semibold">Завдання плану</span><br/>
                C5 Aircross на тест-драйві, авто в шоу-румі.
            </div>
            <div className="d-flex justify-content-start gap-4">

                <a href="#" className="btn btn-sm btn-light-info text-info rounded d-flex align-items-center" target="_blank">
                    <i className="ti ti-grid-3x3 fs-4 me-2"></i>
                    Розкладка Instagram
                </a>
                <a href="#" className="btn btn-sm btn-light-info text-info rounded d-flex align-items-center" target="_blank">
                    <i className="ti ti-circle-dashed fs-4 me-2"></i>
                    Історії
                </a>
            </div>
        </div>

        <div className="blocks-divider-end">
            <div className="row">
                <div className="col-sm-6">
                    <div className="card">
                        <div className="card-body p-3 pb-0 mb-xxl-4 pb-1">
                            <p className="mb-1 fs-3">Видимість</p>
                            <h4 className="fw-semibold fs-7">36,358</h4>
                            <div className="d-flex align-items-center mb-3">
                                <span className="me-2 rounded-circle bg-light-danger round-20 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-arrow-down-right text-danger"></i>
                                </span>
                                <p className="text-dark fs-3 mb-0">+9%</p>
                            </div>
                        </div>
                        <div style={{minHeight: '60px'}}>

                        </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="card">
                        <div className="card-body p-3 pb-0 mb-xxl-4 pb-1">
                            <p className="mb-1 fs-3">Ефективність</p>
                            <h4 className="fw-semibold fs-7">78,298</h4>
                            <div className="d-flex align-items-center mb-3">
                                <span className="me-1 rounded-circle bg-light-success round-20 d-flex align-items-center justify-content-center">
                                    <i className="ti ti-arrow-up-left text-success"></i>
                                </span>
                                <p className="text-dark fs-3 mb-0">+9%</p>
                            </div>

                            <div style={{minHeight: '60px'}}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-n3 text-end fs-2 m-block-gap">
                <a href={'#'} className="text-dark">Більше аналітики »</a>
            </div>

            <div className="card w-100">
                <div className="card-body p-3">
                    <div className="mb-4">
                        <h5 className="card-title fw-semibold mb-2">Активність за планом</h5>
                        <p className="card-subtitle text-muted fs-2">Востаннє оновлено о 15:57.</p>
                    </div>
                    <ul className="timeline-widget mb-0 position-relative mb-n5">
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">09:30</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-primary flex-shrink-0 my-8"></span>
                                <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1">Payment received from John Doe of $385.90</div>
                        </li>
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">10:00 am</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-info flex-shrink-0 my-8"></span>
                                <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                 className="text-primary d-block fw-normal ">#ML-3467</a>
                            </div>
                        </li>
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1">Payment was made of $64.95 to Michael</div>
                        </li>
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-warning flex-shrink-0 my-8"></span>
                                <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New sale recorded <a href="#"
                                                                                                                 className="text-primary d-block fw-normal ">#ML-3467</a>
                            </div>
                        </li>
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">09:30 am</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-danger flex-shrink-0 my-8"></span>
                                <span className="timeline-badge-border d-block flex-shrink-0"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1 fw-semibold">New arrival recorded <a href="#"
                                                                                                                    className="text-primary d-block fw-normal ">#ML-3467</a>
                            </div>
                        </li>
                        <li className="timeline-item d-flex position-relative overflow-hidden">
                            <div className="timeline-time text-dark flex-shrink-0 text-end">12:00 am</div>
                            <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                            </div>
                            <div className="timeline-desc fs-3 text-dark mt-n1">Payment Done</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>);
}
