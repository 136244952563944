import React, {useEffect} from 'react';

import defaultLogo from '../../../assets/img/project-logo-default.png';
import defaultUserProfile from '../../../assets/img/user-profile-default.png';

export default function PlanningNextWeekProjectRow({project, isLast = false}) {
    const [complianceClassName, setComplianceClassName] = React.useState('d-none');
    const [complianceLabel, setComplianceLabel] = React.useState('');

    useEffect(() => {
        if (!project) return;

        if (!project.compliance) {
            setComplianceClassName('bg-dark text-white');
            setComplianceLabel('Відсутня');
        } else if (project.compliance < 50) {
            setComplianceClassName('bg-light-danger text-danger');
            setComplianceLabel('Низька');
        } else if (project.compliance < 85) {
            setComplianceClassName('bg-light-warning text-warning');
            setComplianceLabel('Середня');
        } else {
            setComplianceClassName('bg-light-success text-success');
            setComplianceLabel('Висока');
        }
    }, [project]);


    return (
        <tr>
            <td className={'p-3 ps-0 '+(isLast ? 'border-0' : '')}>
                <div className="d-flex align-items-center">
                    <div className="me-2 pe-1">
                        <img src={project && project.logo ? project.logo : defaultLogo} className="rounded-circle" width="40" height="40" alt={project.title} />
                    </div>
                    <div>
                        <h6 className="fw-semibold mb-0">{project ? (project.title.length > 16 ? project.title.substring(0, 16)+'...' : project.title) : '...'}</h6>
                        <p className="fs-2 mb-0 text-muted">{project ? '@'+project.facebook_username : ''}</p>
                    </div>
                </div>
            </td>
            <td className={'p-3 '+(isLast ? 'border-0' : '')}>
                <div className={'d-flex gap-2 mb-2'}>
                    <img src={defaultUserProfile} className="rounded-circle" width="24" height="24" alt=""/>
                    <div className={'d-flex flex-column'} style={{width: '100px'}}>
                        <p className="fs-2 text-dark mb-0">73%</p>
                        <div className="progress bg-light" style={{height: '3px'}}>
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: '73%', height: '3px'}}
                                 aria-valuenow={73} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div className={'d-flex gap-2'}>
                    <img src={defaultUserProfile} className="rounded-circle" width="24" height="24" alt=""/>
                    <div className={'d-flex flex-column'} style={{width: '100px'}}>
                        <p className="fs-2 text-dark mb-0">10%</p>
                        <div className="progress bg-light" style={{height: '3px'}}>
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: '10%', height: '3px'}}
                                 aria-valuenow={10} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </td>
            <td className={'p-3 pe-0 '+(isLast ? 'border-0' : '')}>
                <span className={'badge fw-semibold py-2 px-3 fs-2 '+complianceClassName}>{complianceLabel}</span>
            </td>
        </tr>
    );
}