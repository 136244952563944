import React from 'react';

import MainLayout from "../../layout/MainLayout";
import {Outlet} from "react-router-dom";

export default function PlanningCover(props) {
    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
}