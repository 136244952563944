import React from 'react';
import {Link} from "react-router-dom";
import CardLoading from "../../ui/CardLoading";

import defaultCover from '../../../assets/img/project-cover-default.png';
import defaultLogo from '../../../assets/img/project-logo-default.png';

export default function ProjectCard({loading, link, title, cover, logo, children}) {
    return (
        <div className={'card card-sq-image overflow-hidden rounded-2'+(loading ? ' loading' : '')}>
            <div className="position-relative">
                <Link to={link} className="sq-image">
                    <img src={cover ? cover : defaultCover} className="card-img-top rounded-0" alt={title} />
                </Link>
                <Link to={link} className="project-logo">
                    <img src={logo ? logo : defaultLogo} className="img-fluid" alt={title} />
                </Link>
                <Link to={link} className="bg-primary rounded-circle p-2 text-white d-inline-flex position-absolute bottom-0 end-0 mb-n3 me-3">
                    <i className="ti ti-chevrons-right fs-4"></i>
                </Link>
            </div>
            <div className="card-body p-3 pt-2">
                <h6 className="fw-semibold one-liner mt-1 mb-3 me-5" style={{marginLeft: '78px'}}>
                    <Link to={link} className="text-dark">{title}</Link>
                </h6>
                {children}
            </div>

            <CardLoading loading={loading} />
        </div>
    );
}
