import React, {useEffect} from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import {useApi} from "../../../providers/ApiProvider";
import {Link} from "react-router-dom";
import {useBreadcrumbs} from "../../../providers/BreadcrumbsProvider";
import TableWrapper from "../../ui/TableWrapper";
import TableLoading from "../../ui/TableLoading";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";

export default function ProjectsList(props) {
    const breadcrumbs = useBreadcrumbs();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [dataItems, setDataItems] = React.useState({});
    const [loading, setLoading] = React.useState(true);


    const syncData = () => {
        setLoading(true);

        projectsModel.list().then((response) => {
            if (response !== false) {
                setDataItems(response.data);
                setLoading(false);
            }
        });
    }


    const onDelete = (pk) => {
        if (window.confirm('Ви впевнені, що хочете видалити цей проєкт?')) {
            setLoading(true);

            projectsModel.delete(pk).then((response) => {
                if (response !== false) {
                    notificationsContext.notify('Проєкт успішно видалено', 'success');

                    syncData();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: null,
            breadcrumbs: []
        });

        document.title = 'Проєкти | Likengale';

        syncData();
    }, []);


    return (
        <SimpleCardContainer title={'Проєкти'}>
            <div className="d-flex justify-content-end mb-3">
                <Link to={'/projects/create'} className={'btn btn-light-success text-success'}>{'Додати новий проєкт'}</Link>
            </div>
            <TableWrapper header={[
                {'label': '', 'cellStyle': {width: '100px'}},
                {'label': 'Назва', 'cellStyle': {minWidth: '40%'}},
                {'label': 'Активний', 'cellStyle': {width: '120px', 'textAlign': 'center'}},
                {'label': '', 'cellStyle': {width: '180px'}},
            ]}>
                {loading ? (<TableLoading loading={loading} columns={4}/>) : dataItems.map((item, index) => {
                    return (
                        <tr key={index} className={item.is_active ? '' : 'row-passive'}>
                            <td className={'text-center'}>
                                <img src={item.logo} alt={item.title} style={{width: '50px'}}/>
                            </td>
                            <td>
                                <h6 className="fs-4 fw-semibold mb-1">
                                    <a href={'/projects/' + item.id} className={'text-primary'}>{item.title}</a>
                                </h6>
                                <span className={'text-muted'}>{item.id}</span>
                            </td>
                            <td className={'text-center'}>{item.is_active ? (<i className={'ti ti-checks fs-4'}></i>) : ('')}</td>
                            <td style={{width: '180px'}}>
                                <div className="d-flex align-items-center justify-content-end gap-3">
                                    <Link to={'/projects/' + item.id}
                                          className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                        <i className="fs-5 ti ti-edit text-primary"></i>
                                    </Link>
                                    <button type="button"
                                            className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onDelete(item.id)
                                            }}>
                                        <i className="fs-5 ti ti-trash text-danger"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </TableWrapper>
        </SimpleCardContainer>
    );
}