import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import {RequireAuth} from "./providers/AuthProvider";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import Faq from "./components/common/Faq";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import Planning from "./components/common/planning/Planning";
import PlanningCover from "./components/common/planning/PlanningCover";
import PublicationView from "./components/publications/publications/PublicationView";
import PublicationViewInsights from "./components/publications/publications/PublicationViewInsights";
import Projects from "./components/projects/projects/Projects";
import ProjectsCreate from "./components/projects/projects/ProjectsCreate";
import ProjectsEdit from "./components/projects/projects/ProjectsEdit";
import ProjectsList from "./components/projects/projects/ProjectsList";
import PlanningProject from "./components/common/planning/PlanningProject";
import PublicationPlanView from "./components/publications/publicationPlans/PublicationPlanView";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                //loader: homeLoader,
                element: (<RequireAuth><MainLayout><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },/*
            {
                path: "brands",
                element: (<RequireAuth><Brands /></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: BrandsCreate,
                    },
                    {
                        path: ":brandId",
                        children: [
                            {
                                path: "themes",
                                children: [
                                    {
                                        path: "create",
                                        Component: ThemesCreate,
                                    },
                                    {
                                        path: ":themeId",
                                        Component: ThemesEdit,
                                    },
                                    {
                                        index: true,
                                        Component: ThemesList,
                                    },
                                ],
                            },
                            {
                                index: true,
                                Component: BrandsEdit,
                            },
                        ]
                    },
                    {
                        index: true,
                        Component: BrandsList,
                    },
                ],
            },*/
            {
                path: "projects",
                element: (<RequireAuth><Projects /></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: ProjectsCreate,
                    },
                    {
                        path: ":projectId",
                        children: [
                            {
                                index: true,
                                Component: ProjectsEdit,
                            },
                        ]
                    },
                    {
                        index: true,
                        Component: ProjectsList,
                    },
                ],
            },
            {
                path: "p",
                element: (<RequireAuth><PlanningCover /></RequireAuth>),
                children: [
                    {
                        index: true,
                        Component: Planning,
                    },
                    /*{
                        path: "create",
                        Component: BrandsCreate,
                    },*/
                    {
                        path: ":projectId",
                        children: [
                            {
                                path: ":contentPlanId",
                                children: [
                                    {
                                        path: ":publicationId",
                                        children: [
                                            {
                                                path: "insights",
                                                Component: PublicationViewInsights,
                                            },
                                            {
                                                index: true,
                                                Component: PublicationView,
                                            },
                                        ]
                                    },
                                    {
                                        index: true,
                                        Component: PublicationPlanView,
                                    },
                                ],
                            },
                            {
                                index: true,
                                Component: PlanningProject,
                            },
                        ]
                    },
                ],
            },
            {
                path: "faq",
                element: (<RequireAuth><MainLayout><Faq /></MainLayout></RequireAuth>),
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
