import React from "react";
import FormTextArea from "../../layout/ui/FormTextArea";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";


export default function PublicationEditCaptionGenerateForm({publication, onTextGenerated}) {

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [generationMode, setGenerationMode] = React.useState(false);
    const [generationTheme, setGenerationTheme] = React.useState('');
    const [generationNotes, setGenerationNotes] = React.useState('');


    const onGenerateClick = () => {
        setLoading(true);
        onTextGenerated('Generated text');
        setLoading(false);
    }


    const resetForm = () => {
        setGenerationTheme('');
        setGenerationNotes('');
        setLoading(false);
        setGenerationMode(false);
    }


    return (<div className="">
        <h4 className="fs-3 text-uppercase text-muted fw-semibold d-flex align-items-center"><i className="ti ti-arrow-left fs-4 me-2"></i>Згенерувати текст з ChatGPT</h4>
        <p className="fs-2">При генерації будуть також враховані тип матеріалу, тема, платформа та схожі публікації.</p>

        {generationMode ? (<>
        <div className="form-group mb-3">
            <label htmlFor="pub-theme" className="form-label">Базовий запит</label>
            <select className="form-select" value={generationTheme} onChange={(e) => setGenerationTheme(e.target.value)} id="pub-theme">
                <option value="theme1">Тема 1</option>
                <option value="theme2">Тема 2</option>
                <option value="theme3">Тема 3</option>
            </select>
        </div>

        <FormTextArea
            label={'Додаткові інструкції'}
            name="pub-notes"
            value={generationNotes}
            onChange={(value) => setGenerationNotes(value)}
            rows={10}
            isCodeMirror={false}
            groupClassName={'mb-3'}
        />

        <div className="d-flex gap-3">
            <ButtonWithLoading
                label={'Згенерувати'}
                className={'btn-dark btn-sm'}
                onClick={onGenerateClick}
                noOriginalIconClassName={true}
                iconClassName={"ti ti-brand-openai fs-3 me-2"}
                loading={loading}
            />
            <button className="btn btn-outline-danger btn-sm d-flex align-items-center" onClick={resetForm}><i className="ti ti-x fs-3"></i></button>
        </div>
        </>) : (<>
            <button className="btn btn-outline-dark btn-sm d-flex align-items-center" onClick={() => {
                setGenerationMode(true)
            }}>
                Надати інструкції
                <i className="ti ti-arrow-narrow-right fs-3 ms-2"></i>
            </button>
        </>)}

    </div>);
}