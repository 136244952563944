import React, {useCallback, useEffect} from "react";
import {useDropzone} from 'react-dropzone'
import FormSingleImageFile from "./FormSingleImageFile";

export default function FormSingleImage({label, name, errors, onChange, value, groupClassName = '', multiple = false, hint = ''}) {
    const onDrop = useCallback(acceptedFiles => {
        setFilesToUpload(acceptedFiles);
        onChange(acceptedFiles[0]);
    }, [onChange]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: multiple});
    const [filesToUpload, setFilesToUpload] = React.useState([]);


    useEffect(() => {
        if (typeof value === 'string') {
            setFilesToUpload([]);
        }
    }, [value]);


    return (
        <div className={'form-group ' + groupClassName}>
            <label className="form-label fw-semibold mb-2" htmlFor={'cf-' + name}>{label}</label>

            <div {...getRootProps()} className={'drop-area' + ((isDragActive) ? ' active' : '')}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="m-0">{'Перетягніть файли сюди...'}</p> :
                        <p className="m-0">{'Перетягніть сюди файли, або натисніть для вибору...'}</p>
                }
            </div>

            <div className="d-flex justify-content-between mt-1">
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
                <div className={'flex-grow-1 text-end fs-2'}>{hint}</div>
            </div>

            <div className={'mt-2'}>
            {filesToUpload && filesToUpload.length ? filesToUpload.map((file, index) => (
                <FormSingleImageFile
                    key={index}
                    file={file}
                    showProgress={false}
                />
            )) : (<>
                {value && (typeof value === 'string') ? (
                <div className="d-flex flex-row gap-3 align-items-center justify-content-start fs-2 fw-semibold text-primary mb-2">
                    <div className={'image-upload-thumbnail'}>
                        {value && (
                            <img src={value} alt={''}/>
                        )}
                    </div>
                    <div className={'d-flex flex-column gap-2 flex-grow-1'}>
                        <div className={'fs-3'}>{value && value.split('/').reverse()[0]}</div>
                    </div>
                </div>
                ) : ('')}
            </>)}
            </div>
        </div>
    );
}