import * as React from "react";
import {useEffect} from "react";


const metaStructure = {
    title: '',
    useSuffix: true,
    suffix: ' | Likengale',
    description: '',
    keywords: '',
    image: '',
    canonical: '',
    favicon: '',
}

const MetaContext = React.createContext(metaStructure);


function MetaProvider({children}) {
    let [metaData, setMetaData] = React.useState(metaStructure);


    const updateMetaData = (newStructure) => {
        let newMetaData = {...metaData};

        Object.keys(newStructure).forEach((key) => {
            if (newMetaData.hasOwnProperty(key) && metaStructure.hasOwnProperty(key)) {
                newMetaData[key] = newStructure[key];
            }
        });

        setMetaData(newMetaData);
    }


    useEffect(() => {
        document.title = metaData.title;
    }, [metaData.title]);

    useEffect(() => {
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.name = "description";
            document.head.appendChild(metaDescription);
        }
        metaDescription.content = metaData.description;
    }, [metaData.description]);

    useEffect(() => {
        let metaKeywords = document.querySelector('meta[name="keywords"]');
        if (!metaKeywords) {
            metaKeywords = document.createElement('meta');
            metaKeywords.name = "keywords";
            document.head.appendChild(metaKeywords);
        }
        metaKeywords.content = metaData.keywords;
    }, [metaData.keywords]);

    useEffect(() => {
        let metaImage = document.querySelector('meta[name="og:image"]');
        if (!metaImage) {
            metaImage = document.createElement('meta');
            metaImage.name = "og:image";
            document.head.appendChild(metaImage);
        }
        metaImage.content = metaData.image;
    }, [metaData.image]);

    useEffect(() => {
        let linkCanonical = document.querySelector('link[rel="canonical"]');
        if (!linkCanonical) {
            linkCanonical = document.createElement('link');
            linkCanonical.rel = "canonical";
            document.head.appendChild(linkCanonical);
        }
        linkCanonical.href = metaData.canonical;
    }, [metaData.canonical]);

    useEffect(() => {
        let linkFavicon = document.querySelector('link[rel="icon"]');
        if (!linkFavicon) {
            linkFavicon = document.createElement('link');
            linkFavicon.rel = "icon";
            document.head.appendChild(linkFavicon);
        }
        linkFavicon.href = metaData.favicon;
    }, [metaData.favicon]);


    let value = {...metaData};
    value.update = updateMetaData;

    return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>;
}


function useMeta() {
    return React.useContext(MetaContext);
}


export {MetaProvider, useMeta};