import React, {useEffect} from 'react';
import Chart from "react-apexcharts";


export default function AreaChart({data, width = '100%', height = '160px', className = 'p-3', shouldBeGraded = false}) {
    const [areaOptions, setAreaOptions] = React.useState({
        chart: {
            id: "pq-insights-chart-"+Math.round((Math.random()*1000000)),
            type: "area",
            height: 120,
            sparkline: {
                enabled: true
            },
            fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
            foreColor: "#adb0bb",
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        fill: {
            type: "gradient",
            gradient: {
                color: "#5D87FF",
                shadeIntensity: 0,
                inverseColors: false,
                opacityFrom: 0.50,
                opacityTo: 0,
                stops: [0, 90],
            },
        },
        markers: {
            show: false,
            size: 0,
        },
        grid: {
            show: false,
        },
        dataLabels: {
            show: false,
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            tooltip: {
                enabled: false,
            }
        },
        tooltip: {
            theme: "dark",
            x: {
                show: false,
                format: 'dd MMM yyyy'
            },
        },
    });
    const [dataSeries, setDataSeries] = React.useState({
        current: {
            name: 'current',
            color: "#5D87FF",
            data: []
        },
        average: {
            name: 'average',
            color: "#adb0bb",
            data: []
        }
    });
    const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);


    useEffect(() => {
        if (!data) return;

        let dataSeriesNew = {...dataSeries};

        Object.keys(data).forEach((key, index) => {
            dataSeriesNew[key].data = data[key];
        });

        /*if (shouldBeGraded && data.series.length) {
            areaOptionsNew.colors = gradeColorsBySeries(data.series);
        }*/

        setDataSeries(dataSeriesNew);
        setDataSeriesAsArray(Object.values(dataSeriesNew));
    }, [data]);


    return dataSeriesAsArray.length && (
        <Chart type={'area'} options={areaOptions} series={dataSeriesAsArray} width={width} height={height} className={className} />
    );
}