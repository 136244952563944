import React, {useEffect} from 'react';
import Chart from "react-apexcharts";


export default function BarChart({data, width = '100%', height = '160px', className = 'p-3', shouldBeGraded = false}) {
    const [barOptions, setBarOptions] = React.useState({
        chart: {
            id: "pq-insights-chart-"+Math.round((Math.random()*1000000)),
            type: "bar",
            height: (height.replace('px', '') - 40),
            fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
            foreColor: "#adb0bb",
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            show: false,
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                barHeight: "60%",
                columnWidth: "20%",
                borderRadius: [3],
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all'
            },
        },
        xaxis: {
            categories: [
                "South Korea",
                "Canada",
                "United Kingdom",
                "Netherlands",
            ],
            tooltip: {
                enabled: false,
            }
        },
        tooltip: {
            theme: "dark",
            x: {
                show: false,
            },
        },
    });
    const [dataSeries, setDataSeries] = React.useState({
        current: {
            name: 'current',
            color: "#5D87FF",
            data: []
        },
        average: {
            name: 'average',
            color: "#adb0bb",
            data: []
        }
    });
    const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);


    useEffect(() => {
        if (!data) return;

        let dataSeriesNew = {...dataSeries};

        Object.keys(data).forEach((key, index) => {
            dataSeriesNew[key].data = data[key];
        });

        /*if (shouldBeGraded && data.series.length) {
            barOptionsNew.colors = gradeColorsBySeries(data.series);
        }*/

        setDataSeries(dataSeriesNew);
        setDataSeriesAsArray(Object.values(dataSeriesNew));
    }, [data]);


    return dataSeriesAsArray.length && (
        <Chart type={'bar'} options={barOptions} series={dataSeriesAsArray} width={width} height={height} className={className} />
    );
}