import React, {useState} from 'react';
import {useAuth} from "../../providers/AuthProvider";
import {Navigate} from "react-router-dom";

const GoogleAuthProcessing = (props) => {
    document.title = 'Authenticating | Likengale';

    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">Authenticating...</h4>
            </div>
        </>
    );
}

export default GoogleAuthProcessing;
