import React, {useEffect} from 'react';
import FormTextArea from "../../layout/ui/FormTextArea";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import PublicationEditCaptionGenerateForm from "./PublicationEditCaptionGenerateForm";


export default function PublicationEditCaption({publication, onEditModeChange, suggestions = []}) {

    const [editMode, setEditMode] = React.useState(false);
    useEffect(() => {
        onEditModeChange(editMode);
    }, [editMode]);


    const resetForm = () => {
        setEditMode(false);
    }


    return editMode ? (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3">
            Редагувати підпис до публікації
        </h4>
        <div className="row">
            <div className="col-lg-7">
                <FormTextArea
                    label={false}
                    name="caption"
                    value={''}
                    onChange={(value) => console.log(value)}
                    rows={24}
                    isCodeMirror={false}
                    groupClassName={'mb-3'}
                />

                <div className="d-flex gap-3">
                    <ButtonWithLoading
                        label={'Зберегти'}
                        className={'btn-primary'}
                        onClick={() => {
                        }}
                        iconClassName={"d-none"}
                        loading={false}
                    />
                    <button className="btn btn-outline-danger" onClick={resetForm}>Скасувати</button>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="bordered-tip">
                    <div className="mb-5">
                        <h4 className="fs-3 text-uppercase text-muted fw-semibold d-flex align-items-center"><i className="ti ti-info-circle fs-4 me-2 d-none"></i>Побажання до тексту</h4>

                        {suggestions.length > 0 && (
                        <div className="d-flex flex-column gap-2">
                        {suggestions.map((s, i) => (
                            <div key={i} className="d-flex gap-2 align-items-start fs-4">
                                <i className="ti ti-checks fs-4 mt-1"></i>
                                <span className="fs-3">{s}</span>
                            </div>
                        ))}
                        </div>
                        )}
                    </div>

                    <PublicationEditCaptionGenerateForm
                        publication={publication}
                        onTextGenerated={(text) => console.log(text)}
                    />
                </div>
            </div>
        </div>
    </>) : (<>
        <h4 className="fs-5 fw-semibold mt-0 mb-3 cursor-pointer" onClick={() => setEditMode(true)}>
            Версії підпису до публікації
            <span className="py-1 ms-3 d-inline-flex align-items-center text-decoration-none" title="Редагувати">
                <i className="ti ti-pencil fs-3 text-dark"></i>
            </span>
        </h4>
        <div className="">
            Весна в розпалі, а ваш Citroën готовий до нових пригод? Підберіть стильні аксесуари, які підкреслять індивідуальність вашого авто та зроблять кожну
            поїздку ще більш комфортною! 🚗✨<br /><br />
            🛍️ Від оригінальних килимків до захисних чохлів та рейлінгів — обирайте те, що сподобається вам!<br /><br />
            Чекаємо на вас «СІТРОЕН на ЦЕНТРАЛЬНОМУ», щоб допомогти обрати ідеальні аксесуари:<br />
            📲 093 378 78 08<br />
            📲 095 157 78 67<br />
            📲 063 257 48 88<br /><br />
            ➡️ м. Харків, вул. В. Панасівська 29.
        </div>
    </>);
}