import React, {useEffect} from 'react';
import {useApi} from "../../../providers/ApiProvider";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/Projects/ProjectsModel";

import PlanningNextWeekProjectRow from "./PlanningNextWeekProjectRow";

export default function PlanningNextWeek({}) {
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [projects, setProjects] = React.useState([]);


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="card w-100">
                <div className="card-body">
                    <div className="d-sm-flex d-block align-items-center justify-content-between mb-7">
                        <div className="mb-3 mb-sm-0">
                            <h5 className="card-title fw-semibold">План на наступний тиждень</h5>
                            <p className="card-subtitle mb-0">на 12 – 18 лютого</p>
                        </div>
                        <div className={'d-none'}>
                            <select className="form-select">
                                <option value="1">March 2023</option>
                                <option value="2">April 2023</option>
                                <option value="3">May 2023</option>
                                <option value="4">June 2023</option>
                            </select>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table align-middle text-nowrap mb-0">
                            <thead>
                            <tr className="text-muted fw-semibold">
                                <th scope="col" className="p-3 ps-0">Проєкт</th>
                                <th scope="col" className={'p-3'}>Готовність</th>
                                <th scope="col" className={'p-3 pe-0'}>Відповідність</th>
                            </tr>
                            </thead>
                            <tbody className="border-top">
                                <PlanningNextWeekProjectRow
                                    project={
                                        {
                                            title: 'Citroen Авто Віа',
                                            facebook_username: 'citroen.kh.ua',
                                            compliance: 87
                                        }
                                    }
                                    isLast={false}
                                />
                                <PlanningNextWeekProjectRow
                                    project={
                                        {
                                            title: 'Fiat & Alfa Romeo Авторіна',
                                            facebook_username: 'fiat.kharkiv.ua',
                                            compliance: 56
                                        }
                                    }
                                    isLast={false}
                                />
                                <PlanningNextWeekProjectRow
                                    project={
                                        {
                                            title: 'Opel Центральний',
                                            facebook_username: 'opel.kharkiv.ua',
                                            compliance: 27
                                        }
                                    }
                                    isLast={false}
                                />
                                <PlanningNextWeekProjectRow
                                    project={
                                        {
                                            title: 'Volvo Car – Львів',
                                            facebook_username: 'volvo.lviv.ua',
                                            compliance: 57
                                        }
                                    }
                                    isLast={false}
                                />
                                <PlanningNextWeekProjectRow
                                    project={
                                        {
                                            title: 'Jaguar Land Rover Дніпро Аеліта Преміум',
                                            facebook_username: 'jaguar.landrover.dnipro',
                                            compliance: 0
                                        }
                                    }
                                    isLast={true}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}