import React, {useEffect} from "react";
import Chart from 'react-apexcharts';

export default function MultiRadialChart({data, width = '100%', height = '160px', className = 'p-3', shouldBeGraded = false}) {

    const [colors, setColors] = React.useState(["#5D87FF", "#13DEB9", "#ffae1f", "#fa896b"]);
    const gradedColors = ["#2A3547", "#fa896b", "#ffae1f", "#13DEB9", "#5D87FF"];


    const gradeColorsBySeries = (series) => {
        let newColors = [];
        for (let i = 0; i < series.length; i++) {
            if (series[i] < 10) {
                newColors.push(gradedColors[0]);
            } else if (series[i] < 50) {
                newColors.push(gradedColors[1]);
            } else if (series[i] < 85) {
                newColors.push(gradedColors[2]);
            } else if (series[i] < 105) {
                newColors.push(gradedColors[3]);
            } else {
                newColors.push(gradedColors[4]);
            }
        }

        return newColors;
    }


    const [multiRadialOptions, setMultiRadialOptions] = React.useState({
        series: [],
        chart: {
            fontFamily: '"Nunito Sans", sans-serif',
            parentHeightOffset: 0,
            height: height,
            width: width,
            type: "radialBar",
        },
        colors: colors,
        plotOptions: {
            radialBar: {
                startAngle: 0,
                endAngle: 360,
                hollow: {
                     margin: 3,
                     size: '10%',
                     background: 'transparent',
                     position: 'front',
                 },
                track: {
                    background: "#f2f2f2",
                    margin: 9
                },
                dataLabels: {
                    show: false,
                },
            },
        },
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            fontSize: '9px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            customLegendItems: [],
            offsetX: -30,
            offsetY: 13,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 3,
                height: 3,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 5,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: -2
            },
            itemMargin: {
                horizontal: 10,
                vertical: -3
            }
        },
        labels: []
    });


    useEffect(() => {
        if (!data) return;

        let multiRadialOptionsNew = {...multiRadialOptions};
        multiRadialOptionsNew.series = data.series ? data.series : [];
        multiRadialOptionsNew.labels = data.labels ? data.labels : [];

        if (shouldBeGraded && data.series.length) {
            multiRadialOptionsNew.colors = gradeColorsBySeries(data.series);
        }

        setMultiRadialOptions(multiRadialOptionsNew);
    }, [data, shouldBeGraded]);


    return data && data.series.length && (
        <Chart type={'radialBar'} options={multiRadialOptions} series={data.series} width={width} height={height} className={className} />
    );
}