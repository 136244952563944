import React, { useEffect } from 'react';
import Chart from "react-apexcharts";


export default function ProjectEfficiencyChart({ data, width = '100%', height = '335px', className = '', barType = 'bar' }) {
	const [chartOptions, setChartOptions] = React.useState({
		chart: {
			id: "pq-insights-chart-" + Math.round((Math.random() * 1000000)),
			type: barType,
			height: (height.replace('px', '') - 40),
			fontFamily: '"Plus Jakarta Sans", "Open Sans", Arial, sans-serif',
			foreColor: "#adb0bb",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				barHeight: "60%",
				columnWidth: "20%",
				borderRadius: [3],
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'all'
			},
		},
		dataLabels: {
			show: false,
			enabled: false,
		},
		legend: {
			show: false,
		},
		stroke: {
			curve: 'smooth'
		},
		markers: {
			show: false,
			size: 0,
		},
		grid: {
			borderColor: "rgba(0,0,0,0.1)",
			strokeDashArray: 3,
			xaxis: {
				lines: {
					show: false,
				},
			},
		},
		xaxis: {
			type: "datetime",
			tooltip: {
				enabled: false,
			}
		},
		tooltip: {
			theme: "dark",
			x: {
				show: false,
				format: 'dd MMM yyyy'
			},
		},
	});
	const [dataSeries, setDataSeries] = React.useState({
		planned: {
			name: 'planned',
			color: "#5D87FF",
			data: []
		},
		actual: {
			name: 'actual',
			color: "#adb0bb",
			data: []
		}
	});
	const [dataSeriesAsArray, setDataSeriesAsArray] = React.useState([]);

	useEffect(() => {
		if (!data) return;

		let dataSeriesNew = { ...dataSeries };

		Object.keys(data).forEach((key, index) => {
			dataSeriesNew[key].data = data[key];
		});

		setDataSeries(dataSeriesNew);
		setDataSeriesAsArray(Object.values(dataSeriesNew));

	}, [data]);


	return dataSeriesAsArray.length && (
		<Chart type={barType} options={chartOptions} series={dataSeriesAsArray} width={width} height={height} className={className} />
	);
}