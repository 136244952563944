import React, {useEffect} from "react";
import Grades from "../../../helpers/Grades";

export default function PublicationCardProgress({progress, label, heading = 'Готовність', className = 'mt-3'}) {
    const [progressClassName, setProgressClassName] = React.useState('light');


    const setClassNameByGrade = () => {
        const curClassName = Grades.getClassNameByGrade(progress);

        setProgressClassName(curClassName);
    }


    useEffect(() => {
        setClassNameByGrade();
    }, [progress]);


    return (
        <div className={className}>
            <h3 className="fs-5 fw-semibold text-start pb-0 d-flex justify-content-between">
                <span className="fs-3">{heading ? heading : ''}</span>
                <span>{Math.round(progress)}%</span>
            </h3>
            <h6 className="card-subtitle mb-2 fs-2 text-muted text-muted text-start">{label}</h6>

            <div className="progress bg-light" style={{height: '4px'}}>
                <div className={'progress-bar bg-'+progressClassName} role="progressbar" style={{width: progress+'%', height: '4px'}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    );
}