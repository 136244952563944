import React, {useEffect} from "react";
import Grades from "../../../helpers/Grades";
import {Tooltip} from "react-tooltip";

export default function InsightsProgress({plan = {
                                            current: 0,
                                            total: 0,
                                            currentLabel: '',
                                            totalLabel: ''
                                        },
                                        average = {
                                            current: 0,
                                            total: 0,
                                            currentLabel: '',
                                            totalLabel: ''
                                        },
                                        best = {
                                            current: 0,
                                            total: 0,
                                            currentLabel: '',
                                            totalLabel: ''
                                        },
                                        hint = '',
                                        heading = 'Готовність',
                                        className = 'mt-3'
}) {
    const [progressClassName, setProgressClassName] = React.useState('light');
    const [progress, setProgress] = React.useState(0);
    const [progressAverageClassName, setProgressAverageClassName] = React.useState('light');
    const [progressAverage, setProgressAverage] = React.useState(0);
    const [progressBestClassName, setProgressBestClassName] = React.useState('light');
    const [progressBest, setProgressBest] = React.useState(0);


    useEffect(() => {
        setProgressClassName(Grades.getClassNameByGrade(plan.current / plan.total * 100));
        let newProgress = Math.round(plan.current / plan.total * 100);
        if (newProgress > 100) {
            newProgress = 100;
        }
        setProgress(newProgress);

        if (average) {
            setProgressAverageClassName(Grades.getClassNameByGrade(average.current / average.total * 100));
            let newProgressAverage = Math.round(average.current / average.total * 100);
            if (newProgressAverage > 100) {
                newProgressAverage = 100;
            }
            setProgressAverage(newProgressAverage);
        }
        if (best) {
            setProgressBestClassName(Grades.getClassNameByGrade(best.current / best.total * 100));
            let newProgressBest = Math.round(best.current / best.total * 100);
            if (newProgressBest > 100) {
                newProgressBest = 100;
            }
            setProgressBest(newProgressBest);
        }
    }, [progress]);


    return (
        <div className={className}>
            <h3 className="fs-5 fw-semibold text-start pb-0 d-flex justify-content-between align-items-center">
                <div className="fs-3">
                    {heading}
                    <i
                        className="fs-3 ti ti-info-square-rounded ms-2"
                        data-tooltip-content={hint}
                        data-tooltip-place="right"
                        data-tooltip-id="my-tooltip"
                    ></i>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <div>{plan.currentLabel}</div>
                    <div className="text-muted fs-6 fw-normal mt-n1">/</div>
                    <div className="text-muted fw-normal">{plan.totalLabel}</div>
                </div>
            </h3>

            <h6 className="card-subtitle mt-3 mb-2 fs-2 text-muted d-flex justify-content-start align-items-center">
                <div className="d-flex align-items-center">
                    <i className="fs-4 ti ti-calendar me-2" style={{marginTop: '-2px'}}></i>
                    Відносно плану
                </div>
            </h6>
            <div className="progress bg-light" style={{height: '6px'}}>
                <div className={'progress-bar bg-' + progressClassName} role="progressbar" style={{width: progress + '%', height: '6px'}}
                     aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            {average && (<>
            <h6 className="card-subtitle mt-3 mb-2 fs-2 text-muted d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <i className="fs-4 ti ti-scale me-2" style={{marginTop: '-2px'}}></i>
                    Відносно середнього для схожих
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <div>{average.currentLabel}</div>
                    <div className="text-muted fw-normal mt-n1">/</div>
                    <div className="text-muted fw-normal">{average.totalLabel}</div>
                </div>
            </h6>
            <div className="progress bg-light mb-n1" style={{height: '4px'}}>
                <div className={'progress-bar bg-' + progressAverageClassName} role="progressbar" style={{width: progressAverage + '%', height: '4px'}}
                     aria-valuenow={progressAverage} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            </>)}

            {best && (<>
            <h6 className="card-subtitle mt-3 mb-2 fs-2 text-muted d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <i className="fs-4 ti ti-trophy me-2" style={{marginTop: '-2px'}}></i>
                    Відносно найкращої серед схожих
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <div>{best.currentLabel}</div>
                    <div className="text-muted fw-normal mt-n1">/</div>
                    <div className="text-muted fw-normal">{best.totalLabel}</div>
                </div>
            </h6>
            <div className="progress bg-light" style={{height: '4px'}}>
                <div className={'progress-bar bg-' + progressBestClassName} role="progressbar" style={{width: progressBest + '%', height: '4px'}}
                     aria-valuenow={progressBest} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            </>)}

            <Tooltip id="my-tooltip"/>
        </div>
    );
}